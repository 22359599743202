//documents download section scss
.op_documents_download_section{
	@include padding-top(120px);
	@include padding-bottom(120px);

	@include screen(tablet){
		@include padding-top(60px);
		@include padding-bottom(60px);		
	}
	@include screen(mobile){
		@include padding-top(50px);
		@include padding-bottom(50px);		
	}
}

.op_download_documents_links_block{

	ul{
		@include ul-clear;
		@include flex;
		@include flexwrap(wrap);
		@include margin-left(-15px);
		@include margin-right(-15px);
		li{
			max-width:33%;
			min-width:33%;
			@include padding-left(15px);
			@include padding-right(15px);
			@include display(block);
			@include margin-bottom(20px);
			a{
				@include font(16px);
				@include family($monsterrg);
				@include display(block);
				@include transition(0.3s);
				@include padding-left(40px);
				@include position(relative);
				color:$gray-800;	
				.download_icon{
					width: 20px;
					left:0;
					top:0;
				    @include display(inline-block);
				    @include margin-top(15px);
					@include position(absolute);
				    @include border-bottom(2px, solid, $primary);
				    .arrow_blk{
				    	width: 15px;
					    height: 2px;
					    background: $primary;
					    right: 0;
					    top: 0;
					    @include position(absolute);
					    @include margin-top(-10px);
					    @include margin-right(2px);
					    @include transition(0.5s);
						@include transform (rotate(90deg));
					    &:after{
					    	content: '';
						    width: 10px;
						    height: 10px;
						    right: 0;
						    top: 0;
						    @include margin-top(-4px);
						   	@include border-top(2px, solid, $primary);
						    @include border-right(2px, solid, $primary);
						    @include position(absolute);
						    @include transition(0.5s);
							@include transform (rotate(45deg));
					    }
				    }
				}

				&:hover,
				&:focus{
					color:$primary;
					.download_icon{
						.arrow_blk{
							@include animationname(upfade);
							@include timeduration(1s);
						}
					}
				}
			}
		}
	}

	@include screen(tabletrotate){
		ul{
			display:table;
			width:100%;
			li{
				float:left;
				min-width:50%;
				max-width:50%;
			}
		}
	}

	@include screen(tablet){
		ul{
			max-width:100%;
			-webkit-flex-wrap:wrap;
					flex-wrap:wrap;
			li{
				min-width:49%;
				max-width:49%;
			}
		}
	}

	@include screen(mobile){
		ul{
			li{
				@include margin-bottom(25px);
				min-width:100%;
				max-width:100%;
			}
		}
	}


	&.op_single_page_links{
		ul{
			li{
				max-width:32%;
				min-width:32%;
			}
		}
		
		@include screen(tablet){
			ul{
				li{
					min-width:49%;
					max-width:49%;
				}
			}
		}

		@include screen(mobile){
			ul{
				li{
					min-width:100%;
					max-width:100%;
				}
			}
		}
	}
}