//lanyard page scss
.op_lanyard_upper_section {
	@include padding(40px 0);
	@include position(relative);



	//image block
	.op_lanyarg_upper_image{
		top:0;
		left:0;
		width:40%;
		height:100%;
		overflow:hidden;
		//@include text-right;
		@include position(absolute);	
		-webkit-background-size:contain;
				background-size:contain;
		-webkit-background-repeat:no-repeat;
				background-repeat:no-repeat;
		-webkit-background-attachment:fixed;
				background-attachment:fixed;	

	}

	//content
	.op_lanyard_upper_content{
		@include padding-left(40px);
	}

	@include screen(mobile){
		@include padding(0px 0 20px);
		.op_lanyarg_upper_image{
			width:100%;
			@include margin-bottom(30px);
			@include position(relative);		
			display:none;
		}


		.op_lanyard_upper_content{
			@include padding-left(0px);
		}
	}


	@include screen(tablet){
		@include padding(0px 0);
		.op_lanyarg_upper_image{
			width:40%;		
		}

		.op_lanyard_upper_content{
			@include padding-left(0px);
		}
	}

	@include screen(tabletrotate){
		@include padding(0px 0);
		.op_lanyarg_upper_image{
			width:30%;		
		}

		.op_lanyard_upper_content{
			@include padding-left(0px);
		}
	}
}


//parent of lanyard blocks
.op_lanyards_blocks_container{
	.row{
		@include margin(0 -30px);	
		.col-md-4{
			@include padding(0 30px);
			border-right:1px solid #ebebeb;
			&:nth-of-type(3n){
				border-right:0px solid #ebebeb;
			}
		}
		@include screen(mobile){
			@include margin(0 -15px);
		}
		.col-md-4{
			@include padding(0 15px);
			&:last-child{
				.op_lanyards_block{
					border-bottom:0;
					margin-bottom:0;
					padding-bottom:10px;
				}
			}
		}
		.col-md-6{
			&:last-child{
				.op_lanyards_block{
					border-bottom:0;
					margin-bottom:0;
				}
			}	
		}
	}	
}


//lanyard block
.op_lanyards_block{
	.op_lanyards_block_image{
		height:150px;
		overflow:hidden;
		@include margin-bottom(20px);
		@include screen(tablet){
			height:100px;
		}
	}

	.op_lanyards_block_content{
		.op_lanyard_info{
			@include margin-bottom(20px);
			h3{
				@include font(20px);
				@include margin(0);
				@include margin-bottom(10px);
				@include family($monstersbd);
			}
			h5{
				@include font(14px);
				@include margin(0);	
			}
		}
		.op_lanyard_inner_content_row{
			@include margin-bottom(30px);
			p{
				@include margin(0);	
			}		
		}

	}

	.op_btn_prime{
		@include margin-top(20px);
		height:40px;
		@include line(40px);
		@include font(12px);
	}

	@include screen(mobile){
		border-bottom:1px solid #ebebeb;
		@include padding-bottom(30px);
		@include margin-bottom(30px);
		.op_lanyards_block_content{
			
		}	
		.op_btn_prime{
			@include margin-top(1px);
		}
	}
	&.op_harness_block{
		@include margin-bottom(80px);
		.op_lanyards_block_image{
			height:280px;
			overflow:hidden;
			@include screen(mobile){
				height:auto;
			}
		}
	}
}

.op_lanyard_button{
	@include padding-top(10px);
}





//harness
.op_harness_product_section{
	@include padding-top(100px);
	@include padding-bottom(10px);

	.container-fluid{
		@include padding-left(0px);
		@include padding-right(0px);
		.row{
			@include margin-left(0px);
			@include margin-right(0px);
			.col-lg-3{
				@include padding-left(0px);
				@include padding-right(0px);	
			}
		}
	}

	.op_lanyards_block{
		.op_lanyards_block_image{
			img{
				width:100%;
			}
		}

		.op_lanyards_block_content{
			@include padding(10px 40px 0)
		}

		.op_btn_prime{
			@include margin-top(1px);
			@include margin-left(40px);
		}
	}

	@include screen(mobile){
		@include padding-top(40px);
		@include padding-bottom(1px);

		.op_lanyards_block{
			@include margin-bottom(50px);
			.op_lanyards_block_content{
				@include padding(10px 15px 0)
				.op_lanyard_inner_content_row{
					@include margin-bottom(20px);
				}
			}

			.op_btn_prime{
				@include margin-top(1px);
				@include margin-left(15px);
			}
		}
	}

	@include screen(tablet){
		@include padding-top(40px);
		@include padding-bottom(1px);
		.op_lanyards_block{
			@include margin-bottom(50px);
		}
	}
}


@media (min-width: 1200px) and (max-width: 1599px) {
	.op_lanyard_upper_section .op_lanyarg_upper_image{
		width:35%;
	}
}