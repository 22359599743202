//subscribe section scss
.op_subscribe_section{
	@include padding-top(45px);
	@include padding-bottom(45px);
	background:$bg-lightgrey;

	.col-lg-5{
		max-width:38%;
	}
	.col-lg-2{
		max-width:23%;
		min-width:23%;	
	}
	@include screen(tablet){
		.col-lg-2{
			max-width:inherit;
			min-width:inherit;		
		}
	}
	@include screen(mobile){
		.col-lg-5,
		.col-lg-2{
			max-width:inherit;
			min-width:inherit;		
		}
	}

	.op_subscribe_heading_block{
		@include flex;
		@include padding-bottom(30px);
		h3{
			@include font(24px);
			@include family($oxyrg);
			color:$primary;
			@include margin(0);
			@include padding-right(30px);
		}
		p{
			@include font(14px);
			@include family($oxyrg);
			color:$text-dark;
			@include marginall(auto,0,auto,0);	
		}
	}

	form{
		.form-control,
		.op_select_box{
			width:100%;
			height:60px;
			background-color:$white;
			color:$gray-600;
			@include font(16px);
			@include family($monsterrg);
			@include radius(0);
			@include border-none;
			@include padding(5px 40px);
			@include box-shadow(0px, 2px, 6px, 0px, rgba(0, 0, 0, 0.1));
			&:focus{
				outline:none;
			}
		}
		.op_select_box{
			@include padding(0px);
			select{
				width:100%;
				height:100%;
				color:$gray-600;
				@include font(16px);
				@include family($monsterrg);
				@include border-none;
				@include padding(5px 40px);
				&:focus{
					outline:none;
				}
			}
		}
		.op_btn_prime{
			width:100%;
			-webkit-border-radius:0;
					border-radius:0;
			-webkit-appearance: none;
		   -moz-appearance: none;
		   appearance:none;
		}
		textarea.form-control{
			height:120px;
			@include padding(15px);
		}

		@include screen(tablet){
			.form-control,
			.op_select_box{	
				font-size:14px;
				@include padding(5px 20px);
				select{
					@include font(14px);
					@include padding(5px 20px);
				}
			}
			.op_select_box{
				@include padding(0px);	
				&:after{
					width:10px;
					height:10px;
					margin: 22px 15px 0 0;
				}
			}	
		}
	}

	@include screen(mobile){
		.op_subscribe_heading_block{
			@include display(block);
			h3{
				font-size:20px;
				@include margin-bottom(10px);
			}
		}

		form{
			.form-control,
			.op_select_box{
				@include margin-bottom(10px);
			}
		}
	}

	@include screen(tablet){
		.op_subscribe_heading_block{
			@include display(block);
			h3{
				@include margin-bottom(8px);
			}
		}
	}
}

.op_select_box{
	@include padding-right(15px);
	@include position(relative);
	&:after{
		content:'';
		width:15px;
		height:15px;
		right:0;
		top:0;
		@include margin(20px 25px 0 0);
		@include position(absolute);
		-webkit-transform:rotate(135deg);
				transform:rotate(135deg);
		@include border-top(2px, solid, $primary);
		@include border-right(2px, solid, $primary);
		@include zindex(0);
	}
	select{
		@include zindex(1);
		@include position(relative);
		background:transparent;
		-webkit-appearance:none;
				appearance:none;
	}
}
.op_contact_form_block{
	.op_select_box{
		&:after{
			width:10px;
			height:10px;
			@include margin(15px 20px 0 0);
		}
	}
}


.op_subscribe_thanks{
	font-size: 30px;
    text-align: center;
    color:$primary;
    @include family($monsterbd);
}
.op_contactus_thanks{
	margin-top:60px;
	font-size: 30px;
    text-align: center;
    color:$primary;
    @include family($monsterbd);	
}

input[type="text"],
input[type="email"],
input[type="submit"],
input[type="number"],
button,
select,
textarea{
	-webkit-appearance: none;
		-moz-appearance: none;
			  appearance:none;
}