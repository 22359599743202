//typography page
.typo_templ{
    @include text-center;
    @include padding(80px 0);
    @include border-top(1px, solid, #f0f0f0);
}
.othr_styl{
    @include padding-top(40px);
}


.lt_typo_heading_block{
    @include padding-bottom(40px);
    h1{
        &.main_heading{
            color:$text-dark;
            @include family($oxylt);
            @include font(48px);
            @include margin-bottom(5px);
            @include padding-bottom(10px);
        }
    }
    p{
        @include family($monstersbd);
        @include font(18px);
        @include line(30px);
        @include margin-bottom(10px);
    }
}

.typo_section_title{
    @include font(24px);
    @include family($oxylt);
    color:$gray-600;
    @include padding-bottom(10px);
    @include margin-bottom(10px);
}

hr{
    @include margin(40px 0px); 
}
/*==========| headings section |==========*/
h1,
.h1{
    @include font(30px);
    @include line(36px);
    margin-top: 0;
    margin-bottom: 20px;
}
h2,
.h2{
    @include font(24px);
    @include line(30px);
    margin-top: 0;
    margin-bottom: 20px;
}
h3,
.h3{
    @include font(21px);
    @include line(26px);
    margin-top: 0;
    margin-bottom: 20px;
}
h4,
.h4{
    @include font(18px);
    @include line(24px);
    margin-top: 0;
    margin-bottom: 20px;
}
h5,
.h5{
    @include font(16px);
    @include line(20px);
    margin-top: 0;
    margin-bottom: 20px;
}
h6,
.h6{
    @include font(14px);
    @include line(18px);
    margin-top: 0;
    margin-bottom: 20px;
}


/*==========| blockquote section |==========*/
blockquote {
    padding: 25px 15px ;
    margin: 0;
    position: relative;
    background: #f3f3f3;
    border-left: 4px solid $primary;
    @include font(18);
    @include family($monstersbd);
    @include text-left;
    font-style: italic;
    color: #333;
}
blockquote h1,
blockquote h2,
blockquote h3,
blockquote h4,
blockquote h5,
blockquote h6,
blockquote p{
    margin-top: 0;
    margin-bottom: 0;
}

/*==========| colour variables |==========*/
.theme{
    color: $primary;
}
.red{
    color: #ff0000;
}
.grey{
    color: $gray-600;
}

/*==========| lists section |=========*/
ul,
ol {
    margin: 0;
    padding: 0;
}
#page_content ul li,
#page_content ol li{
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
}


/*=====| unordered list |=====*/
#page_content ul li{
    display: block;
    padding-left: 20px;
    position: relative;
}
#page_content ul li:before{
    content: '';
    left: 0;
    top: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-top: 12px;
    position: absolute;
    background: $primary;
}

/*======| ordered list |=====*/
#page_content ol{
    padding-left: 15px;
}

/*==========| link |==========*/
#page_content .blk_anchr{
    display: inline-block;
    padding: 5px 15px;
    background: #f9f9f9;
    margin: 0 2px;
}



/*==========| table section |==========*/
#page_content table{
    font-size: 16px;
    font-weight: 400;
    color: #454545;
    width: 100%;
    border:0;
    margin: 20px 0;
    width: 100%;
}
#page_content table tr th,
#page_content table tr:first-child td{
    font-size: 18px;
    font-weight: 700;
}
#page_content table th,
#page_content table td{
    padding: 5px;
}

/*=====| normal table |=====*/
#page_content table tr:nth-child(even) {
    background-color: #efefef;
}




/*=====| design table \=====*/
#page_content table.border_table tr:nth-child(even) {
    background-color: transparent;
}
#page_content table.border_table tr th,
#page_content table.border_table tr td{
    text-align: left;
    padding: 10px;
    border-top: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    vertical-align: middle;
}
#page_content table.border_table tr:first-child th,
#page_content table.border_table tr:first-child td{
  border-top: 0;
}
#page_content table.border_table th:first-child,
#page_content table.border_table td:first-child{
  border-left: 0;
}


/*==========| images section |==========*/
#page_content img{
    width: auto;
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}
#page_content img.set_border{
    border: 1px solid #f0f0f0;
    padding: 5px;
}
#page_content img.set_frame{
    padding: 5px;
    -webkit-box-shadow:0 0px 10px 2px rgba(0,0,0,0.1);
            box-shadow:0 0px 10px 2px rgba(0,0,0,0.1);
}
#page_content img.round_corner{
    border-radius:6px;
}


.block_accor_design h4.panel-title,
.nrml_accor_design h4.panel-title{
    margin-bottom: 0!important;
}





/*==========| not for use |==========*/
.well{
    @include padding(30px);
    @include margin-bottom(30px);
    @include radius(0);
    //@include border(1px, solid, #f0f0f0);
    background: transparent;
    -webkit-box-shadow:none;
            box-shadow:none;
}
xmp{
    @include font(12px);
    color: #666;
}
.exp_cnt{
    @include font(16px);
    @include line(30px);
    background: #f8f8f8;
    @include padding(30px);
}