//header section scss
.op_header_section{
	
	//top header start
	.op_top_header{
		height:40px;
		@include margin-top(-1px);
		background-color:$gray-100;
		.op_topheader_right_content{
			@include text-right;
			@include position(relative);

			&:after,
			&:before{
				content:'';
				display:table;
				clear:both;
			}
			.op_top_nav_list_block{
				float:right;
				ul{
					@include ul-clear;
					li{
						@include display(inline-block);
						@include position(relative);
						@include line(40px);
						a{
							@include font(14px);
							@include family($oxybd);
							color:$gray-600;
							@include paddingall(0, 5px, 0, 5px);
							&:after{
								content:'|';
								right:0;
								top:0;
								@include font(14px);
								color:$gray-600;
								@include margin-top(1px);
								@include margin-right(-5px);
								@include position(absolute);
							}
							&:hover,
							&:focus{
								color:$primary;
							}
						}
						&:last-child{
							a{
								&:after{
									@include display(none);
								}
							}
						}
						&.current-menu-item{
							a{
								color:$primary;
							}
						}
					}
				}
			}

			.op_top_search_block{
				width:60px;
				float:right;
				@include text-center;
				button{
					font-size: 18px;
				    line-height: 40px;
				    cursor: pointer;
					color:$primary;
					border:0;
					background:transparent;
					i{
						background: -webkit-linear-gradient( #a71252, $primary);
						background: linear-gradient( #a71252, $primary);
						-webkit-background-clip: text;
						background-clip: text;
						-webkit-text-fill-color: transparent;
						text-fill-color: transparent;
					}
				}
				@include screen(mobile){
					width:50px;
				}
			}
			.op_topheader_cart_button{
				@include margin-left(10px);
				float:right;
				a.op_cart_top_icon{
					@include font(12px);
					@include family($oxybd);
					color:$white;
					height:40px;
					@include line(40px);
					@include text-center;
					@include display(block);
					background: -webkit-linear-gradient (#a71252,$primary);
								background: linear-gradient(#a71252,$primary);
					@include padding(0 20px);
					@include position(relative);
					&:after{
						display:none;
						content:'';
						left:0;
						bottom:0;
						width:100%;
						height:10px;
						background:$primary;
						@include margin-bottom(-9px);
						@include position(absolute);
						@include border-bottom-left-radius(5px);
						@include border-bottom-right-radius(5px);
					}

					.op_top_cart_item{
						padding: 0 5px;
    					border-radius: 3px;
						@include line(20px);
						height:19px;
						background:#fff;
						text-align:center;
						float:right;
						margin-top:11px;
						@include position(relative);
						.op_top_cart_item_new{
							margin-top:0;
							background: -webkit-linear-gradient(#a71252, #ca3c3c);
						    background: linear-gradient(#a71252, #ca3c3c);
						    -webkit-background-clip: text;
						    background-clip: text;
						    -webkit-text-fill-color: transparent;
						    text-fill-color: transparent;
						}
					}
					.op_top_cart_text{
						left:0;
						top:0;
						@include line(15px);
						@include margin-left(14px);
						@include margin-top(16px);
						@include position(absolute);	
					}
					img{
						@include margin-left(3px);
						@include margin-top(4px);
					}
					i{
						font-size:24px;
						line-height:38px;
						margin-right:8px;
					}
				}
			}

			.op_top_social_block{
				float:right;
				padding-left:10px;
				ul{
					@include ul-clear;
					li{
						display:inline-block;
						a{
							color:#c2c2c2;
							font-size:22px;
							line-height:42px;
							padding:0 5px;
							i{
								-webkit-transition:0.3s;
										transition:0.3s;
							}
							&:hover i,
							&:focus i{
								background: -webkit-linear-gradient( #a71252, $primary);
								background: linear-gradient( #a71252, $primary);
								-webkit-background-clip: text;
								background-clip: text;
								-webkit-text-fill-color: transparent;
								text-fill-color: transparent;
							}
						}
					}
				}
				@include screen(mobile){
					li{
						display:inline-block;
					}
				}

				@include screen(tablet){
					padding:0;
				}
			}
		}

		//media query for mobile
		@include screen (mobile){
			display:none;
			height:43px;
			.row{
				margin-left:-4px;
				margin-right:-4px;
				.col-5,
				.col-7{
					padding-left:4px;
					padding-right:4px;
				}
			}
			.op_topheader_left_content{	
				#google_language_translator{
					.goog-te-combo{
						margin:0;
						margin-top:5px;
						@include screen(mobile){
							width:100%;
						}
					}
				}
			}	
			.op_topheader_right_content{
				.op_top_nav_list_block{
					@include display(block);
					ul{

						li{
							a{
								font-size:12px;
							}
						}
					}
					@include screen(mobile){
						display:none;
					}				
				}
				.op_topheader_cart_button{
					@include display(none);
					a{
						&:after{
							@include display(none);
						}
						img{
							@include margin-top(-1px);
						}
						.op_top_cart_text{
							@include margin-top(14px);
						}
					}
				}
			}
		}


		@include screen (tablet){
			.op_topheader_right_content{
				.op_top_nav_list_block{
					@include display(block);
				}
				.op_topheader_cart_button{
					@include display(none);
					a{
						&:after{
							@include display(none);
						}
						img{
							@include margin-top(-1px);
						}
						.op_top_cart_text{
							@include margin-top(14px);
						}
					}
				}
			}
		}	 
	}

	//main header
	.op_main_header{
		@include paddingall(30px, 0, 30px, 0);

		//menu block
		.op_menu_block{
			text-align:right;
			@include position(relative);
			@include padding(25px 0);
			padding-right:90px;
			ul{
				text-align:left;
				@include ul-clear;
				@include display(inline-block);
				li{
					@include padding-right(22px);
					@include display(inline-block);
					&:last-child{
						@include padding-right(0);
					}
					a{
						@include font(14px);
						color:$gray-600;
						@include display(block);
						@include family($monstersbd);
						@include position(relative);
						&:before{
							content:'[';
							top:0;
							left:0;
							opacity:0;
							@include margin-left(-5px);
							@include margin-top(-1px);
							color:$primary;
							@include position(absolute);
							@include transition(0.3s);
						}
						&:after{
							content:']';
							top:0;
							right:0;
							opacity:0;
							@include margin-right(-5px);
							@include margin-top(-1px);
							color:$primary;
							@include position(absolute);
							@include transition(0.3s);
						}
						&:hover,
						&:focus{
							&:before{
								opacity:1;
								@include margin-left(-10px);
							}
							&:after{
								opacity:1;
								@include margin-right(-10px);
							}
						}
					}
					&.current-menu-item{
						a{
							&:before{
								opacity:1;
								@include margin-left(-10px);
							}
							&:after{
								opacity:1;
								@include margin-right(-10px);
							}
						}
					}	
					&.current-menu-parent{
						a{
							&:before{
								opacity:1;
								@include margin-left(-10px);
							}
							&:after{
								opacity:1;
								@include margin-right(-10px);
							}
						}
					}
					ul{
						width:180px;
						background:$white;
						@include paddingall(10px, 20px, 20px, 30px);
						@include position(absolute);
						@include display(none);	
						@include margin-left(-20px);
						@include zindex(10);
						li{
							@include display(block);
							a{
								color:$gray-500;
								@include font(12px);
								@include line(28px);
								@include family($monsterrg)
								@include display(inline-block);	
							}	
						}
					}

					&:hover,
					&:focus{
						a{
							&:before{
								opacity:1;
								@include margin-left(-10px);
							}
							&:after{
								opacity:1;
								@include margin-right(-10px);
							}
						}
						ul{
							@include display(block);	
							li{
								a{
									&:before{
										content: "\f0da";
										font: normal normal normal 14px/1 FontAwesome;
										@include margin-left(-15px);
										margin-top:5px;
									}
									&:before,
									&:after{
										opacity:0;
									}
									&:hover,
									&:focus{
										
										&:before{
											opacity:1;
											
										}
									}
								}
							}	
						}
					}
				}
			}

			&.op_right_menu{
				.op_nav_logo{
					@include position(absolute);
				    right: 0;
				    top: 0;
				    width: 70px;
				    @include margin-top(20px);
				}
			}

			@include screen(tabletrotate){
				ul{
					li{
						@include padding-right(12px);
						a{
							@include font(12px);
						}
					}
				}
				&.op_right_menu{
					.op_nav_logo{
						width:60px;
						@include margin-top(20px);
					}
				}
			}
		} 

		//logo block
		.op_logo_block{
			padding-top:12px;
		}

		//media query for mobile
		@include screen (mobile){
			@include paddingall(20px, 0, 15px, 0);
			.op_menu_block{
				@include display(none);
			}

			//nav toggle
			.op_mobile_nav_toggle_block{
				@include flex;
				height:42px;
				a{
					@include marginall(auto, 0, auto, 0);
					width:30px;
					@include display(block);
					span{
						height:3px;
						border-radius:4px;
						@include marginall(4px, 0, 4px, 0);
						@include display(block);
						background-color:$primary;
						&:first-child{
							background:#a71252;
						}
						&:nth-child(2){
							width:22px;
							background: -webkit-linear-gradient( #a71252, $primary);
									background: linear-gradient( #a71252, $primary);
						}
					}
				}
			}

			.op_logo_block{
				padding-top:6px;
			}
			
			&.op_right_menu{
				.op_nav_logo{
					@include display(none);
				}
			}
		}

		@include screen (tablet){
			@include paddingall(20px, 0, 15px, 0);
			.op_menu_block{
				@include display(none);
			}

			//nav toggle
			.op_mobile_nav_toggle_block{
				@include flex;
				height:42px;
				a{
					@include marginall(auto, 0, auto, 0);
					width:30px;
					@include display(block);
					span{
						height:3px;
						border-radius:4px;
						@include marginall(4px, 0, 4px, 0);
						@include display(block);
						background-color:$primary;
						&:first-child{
							background:#a71252;
						}
						&:nth-child(2){
							width:22px;
							background: -webkit-linear-gradient( #a71252, $primary);
									background: linear-gradient( #a71252, $primary);
						}
					}
				}
			}

			.op_logo_block{
				padding-top:5px;
			}
			
			&.op_right_menu{
				.op_nav_logo{
					@include display(none);
				}
			}
		}


		
	}
}

@include screen(mobile){
	.op_top_search_block{
	    width: 30px;
	    float: left;
	    text-align: center;
	    button{
	    	font-size: 18px;
		    line-height: 40px;
		    cursor: pointer;
		    color: #ca3c3c;
		    border: 0;
		    background: transparent;
	    }
	}
	.op_mobile_menu_container{
		.op_top_search_block{
			button{
				color:#797979!important;
			}	
		}
	}	
}

@include screen(tablet){
	.op_main_header{	
		.op_top_search_block{
			display:none;	    
		}
	}	
	.op_mobile_menu_container{
		.op_top_search_block{
			display:none;
			button{
				color:#797979!important;
			}	
		}
	}	
}


//mobile menu container
.op_mobile_menu_container{
	width:100%;
	min-height:100%;
	left:0;
	top:0;
	background-color:#3f3f3f;
	@include display(none);
	@include position(absolute);
	@include zindex(10);
}
@include screen(mobile){
	//mobile menu container
		.op_mobile_menu_container{
			.op_mobile_menu_container_inner{
				@include padding(20px 15px);
				@include margin(auto);
				margin-top:0px;

				ul{
					margin:auto;
					@include ul-clear;
					li{
						text-align:center;
						@include display(block);
						border-bottom:1px solid #494949;
						a{
							color:#fff;
							@include family($monstermd);
							@include font(16px);
							@include line(60px);
							@include display(inline-block);	
							@include text-center;
							position:relative;
						}
						&.menu-item-has-children{
							a{
								&:after{
									content:'\f107';
									top:0;
									right:0;
									margin-right:-30px;
									position:absolute;
									font-size:30px;
									background: -webkit-linear-gradient( #a71252, $primary);
									background: linear-gradient( #a71252, $primary);
									-webkit-background-clip: text;
									background-clip: text;
									-webkit-text-fill-color: transparent;
									text-fill-color: transparent;
									font-family:FontAwesome;
								}
							}
							ul{
								display:none;
								li{
									a{
										&:after{
											display:none;
										}
									}
								}
							}
							&.child_active{
								a{
									&:after{
										content:'\f106';
									}
								}	
							}
						}
						ul{
							li{
								border-bottom:0;
								a{
									color:#dadada;
									@include family($monsterrg);
									@include font(16px);
									@include line(45px);
								}
							}
						}
					}
				}
			}
		}
	.op_navigation_block{
		.op_mobile_menu_container{
			@include display(block);
			@include timeduration(0.4s);
			@include animationname(display);
		}
	}
	.unactive_menu{
		.op_mobile_menu_container{
			@include display(block);
			@include opacity(0);
			@include timeduration(0.4s);
			@include animationname(unshown);
		}
	}
	.op_mob_container_header{
		padding-bottom:20px;
		.op_close_icon_block{
			.op_close_menu{
				width:15px;
				height:15px;
				display:block;
				margin-top:2px;
			}
		}
		
	}
	.op_mob_menu_logo{
		margin-top:6px;
	}
	.op_close_icon_block{
		padding-top:3px;
	}
	.op_top_search_block button{
		//color:#797979;
	}
	.col-4{
		padding-left:0;
	}
}

@include screen(tablet){
	//mobile menu container
		.op_mobile_menu_container{
			.op_mobile_menu_container_inner{
				@include padding(20px 15px);
				@include margin(auto);
				margin-top:10px;

				ul{
					margin:auto;
					@include ul-clear;
					li{
						text-align:center;
						@include display(block);
						border-bottom:1px solid #494949;
						a{
							color:#fff;
							@include family($monstermd);
							@include font(16px);
							@include line(45px);
							@include display(inline-block);	
							@include text-center;
							position:relative;
						}
						&.menu-item-has-children{
							a{
								&:after{
									content:'\f107';
									top:0;
									right:0;
									margin-right:-30px;
									position:absolute;
									font-size:30px;
									background: -webkit-linear-gradient( #a71252, $primary);
									background: linear-gradient( #a71252, $primary);
									-webkit-background-clip: text;
									background-clip: text;
									-webkit-text-fill-color: transparent;
									text-fill-color: transparent;
									font-family:FontAwesome;
								}
							}
							ul{
								display:none;
								li{
									a{
										&:after{
											display:none;
										}
									}
								}
							}
							&.child_active{
								a{
									&:after{
										content:'\f106';
									}
								}	
							}
						}
						ul{
							li{
								border-bottom:0;
								a{
									color:#dadada;
									@include family($monsterrg);
									@include font(16px);
									@include line(45px);
								}
							}
						}
					}
				}
			}
		}
	.op_navigation_block{
		.op_mobile_menu_container{
			@include display(block);
			@include timeduration(0.4s);
			@include animationname(display);
		}
	}
	.unactive_menu{
		.op_mobile_menu_container{
			@include display(block);
			@include opacity(0);
			@include timeduration(0.4s);
			@include animationname(unshown);
		}
	}
	.op_mob_container_header{
		padding-bottom:20px;
		.op_close_icon_block{
			.op_close_menu{
				width:15px;
				height:15px;
				display:block;
			}
		}
		
	}
	.op_mob_menu_logo{
		margin-top:6px;
	}
	.op_close_icon_block{
		padding-top:3px;
	}
	.op_top_search_block button{
		//color:#797979;
	}
	.col-4{
		padding-left:0;
	}
}


.goog-te-gadget{
	padding-top:3px;
	font-size:0!important;
	select{
		font-size:14px!important;
		-webkit-appearance :menulist;
				appearance :menulist;
	}
	span{
		display:none;
	}
	@include screen(desktop){
		font-size:12px!important;
		span{
			display:inline-block;
		}
		div{
			display:inline-block;	
			margin-right:6px;
		}
	}
}

.op_top_social_block{
	@include screen(mobile){
		padding:15px 0;
		ul{
			text-align:center;
			li{
				display:inline-block!important;
				border-bottom:0!important;
				a{
					padding:0 12px;
					i{
						font-size:24px;
						color:#323232;
					}
				}
			}
		}	
	}

	@include screen(tablet){
		padding:15px 0;
		ul{
			text-align:center;
			li{
				display:inline-block!important;
				border-bottom:0!important;
				a{
					padding:0 12px;
					i{
						font-size:24px;
						color:#323232;
					}
				}
			}
		}	
	}
}



@include screen(mobile){
	//mobile cart
	.op_mobile_cart_icon_block{
		margin-right:-15px;
		float:right;
		width:80px;
		
		.op_cart_top_icon{
			font-size:20px;
		    font-family: montserratbold;
		    color: #fff;
		    height: 40px;
		    line-height: 40px;
		    text-align: left;
		    display: block;
		    background: -webkit-linear-gradient #a71252, #ca3c3c;
		    background: linear-gradient(#a71252, #ca3c3c);
		    border-top-left-radius:6px;
		    border-bottom-left-radius:6px;
		    padding: 0 10px;
		    position: relative;
		    i{
		    	float:left;
		    	margin-top: 9px;
		    }
			.op_top_cart_item{
				font-size:12px;
				padding: 0 5px;
			    border-radius: 3px;
			    line-height: 20px;
			    height: 19px;
			    background: #fff;
			    text-align: center;
			    float: left;
			    margin-left:10px;
			    margin-top: 11px;
			    position: relative;
			    .op_top_cart_item_new {
				    margin-top: 0;
				    background: -webkit-linear-gradient(#a71252, #ca3c3c);
				    background: linear-gradient(#a71252, #ca3c3c);
				    -webkit-background-clip: text;
				    background-clip: text;
				    -webkit-text-fill-color: transparent;
				    text-fill-color: transparent;
				}
			}
		}
	}
}

@include screen(tablet){
	//mobile cart
	.op_mobile_cart_icon_block{
		margin-right:-15px;
		float:right;
		width:80px;
		
		.op_cart_top_icon{
			font-size:20px;
		    font-family: montserratbold;
		    color: #fff;
		    height: 40px;
		    line-height: 40px;
		    text-align: left;
		    display: block;
		    background: -webkit-linear-gradient #a71252, #ca3c3c;
		    background: linear-gradient(#a71252, #ca3c3c);
		    border-top-left-radius:6px;
		    border-bottom-left-radius:6px;
		    padding: 0 10px;
		    position: relative;
		    i{
		    	float:left;
		    	margin-top: 9px;
		    }
			.op_top_cart_item{
				font-size:12px;
				padding: 0 5px;
			    border-radius: 3px;
			    line-height: 20px;
			    height: 19px;
			    background: #fff;
			    text-align: center;
			    float: left;
			    margin-left:10px;
			    margin-top: 11px;
			    position: relative;
			    .op_top_cart_item_new {
				    margin-top: 0;
				    background: -webkit-linear-gradient(#a71252, #ca3c3c);
				    background: linear-gradient(#a71252, #ca3c3c);
				    -webkit-background-clip: text;
				    background-clip: text;
				    -webkit-text-fill-color: transparent;
				    text-fill-color: transparent;
				}
			}
		}
	}
}



//search container
.op_search_container{
	width:100%;
	height:100%;
	left:0;
	top:0;
	@include display(none);
	@include position(fixed);
	@include zindex(10);
	background: -webkit-linear-gradient( rgba(167,18,82,0.9), rgba(202,60,60,0.9));
	background: linear-gradient( rgba(167,18,82,0.9), rgba(202,60,60,0.9));	

	.op_search_container_inner{
		width:100%;
		margin:auto 0;
		.op_search_content_block{
			a{
				font-size:16px;
				color:#fff;
				@include family($monstermd);
				img{
					top: -3px;
				    position: relative;
				    margin-left: 8px;
				}
			}
			.op_search_form_block{
				padding-top:50px;
				form{
					margin-bottom:15px;
					border-bottom:3px solid #fff;
					input{
						background:transparent;
						border:0;
						font-size:48px;
						color:#fff;
						width:-webkit-calc(100% - 60px);
						width:calc(100% - 60px);
						@include family($monstersbd);
						&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
						  color: rgba(255,255,255,0.4);
						}
						&::-moz-placeholder { /* Firefox 19+ */
						  color: rgba(255,255,255,0.4);
						}
						&:-ms-input-placeholder { /* IE 10+ */
						  color: rgba(255,255,255,0.4);
						}
						&:-moz-placeholder { /* Firefox 18- */
						  color: rgba(255,255,255,0.4);
						}
						&:focus{
							outline:none;
						}
						@include screen(mobile){
							font-size:30px;
						}
					}
					button{
						padding:0 15px;
						font-size:48px;
						color:#fff;
						border:0;
						opacity:0.4;
						background:transparent;
						cursor:pointer;
						@include transition(0.3s);
						@include screen(mobile){
							font-size:30px;
						}
					}
				}
				p{
					margin:0;
					color:#fff;
					font-size:14px;
					@include family($monstermd);
					span{
						@include family($monsterbd);
					}
				}
				&.search_btn_active{
					button{
						opacity:1;
					}
				}
			}
		}	
	}
}
.op_search_open{
	.op_search_container{
		@include flex;
		@include timeduration(0.4s);
		@include animationname(display);
	}
}
.unactive{
	.op_search_container{
		@include flex;
		@include opacity(0);
		@include timeduration(0.4s);
		@include animationname(unshown);
	}
}