//parallax section scss
.op_parallax_section{
	@include padding-top(100px);
	@include padding-bottom(100px);
	background-image:url(../images/parallax.jpg);
	@include back-attach(fixed);
	@include back-size(cover);
	@include text-center;

	.op_block_title{
		color:$white;
		@include margin-bottom(35px);
	}
	p{
		color:$white;
		@include line(24px);
		@include font(14px);
		@include family($monstersbd);
		@include margin-bottom(40px);
		@include padding(0 35px);
	}

	@include screen(tabletrotate){
		@include back-attach(inherit);
	}

	@include screen(tablet){
		@include padding-top(60px);
		@include padding-bottom(60px);
		@include back-attach(inherit);
	}
	@include screen(mobile){
		@include padding-top(40px);
		@include padding-bottom(40px);
		@include back-attach(inherit);
		p{
			@include padding(0);
		}
	}
}