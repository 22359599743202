//product single scss
.op_product_single_page{
	@include padding-top(60px);
	@include padding-bottom(60px);
	@include screen(mobile){
		@include padding-top(20px);
		@include padding-bottom(0px);
	}
}

.op_product_single_images_block{
	padding-right:60px;
	position:relative;
	.woocommerce-product-gallery {
		position:relative;
	}
	.op_product_single_image_gallery{
		@include padding-top(30px);
		@include flex;
		.op_product_single_small_image{
			min-width:100px;
			max-width:100px;
			@include paddingall(0,5px,0,5px);
			img{
				width:auto;
				max-width:90px;
				max-height:90px;
			}
		}
	}

	.woocommerce-product-gallery__trigger{
		position:absolute;
		right:0;
		top:0;
		z-index:1;
		width:30px;
		height:30px;
		line-height:30px;
		opacity:0;
		text-align:center;
		background:$primary;
	}
	&:hover{
		.woocommerce-product-gallery__trigger{
			opacity:1;
		}
	}

	@include screen(mobile){
		padding-right:0;
		img{
			width:100%;
			height:auto;
		}
	}

	img{
		width:100%;
		height:auto;
	}
	.flex-viewport{
		width:100%;
		margin-bottom:10px;
	}
	.flex-control-thumbs{
		//@include flex;
		//margin:0 -5px;
		li{
			cursor:pointer;
			padding:0 5px;
			list-style:none;
			//min-width:20%;
			//max-width:20%;
			img{
				width:100%;
			}
		}
	}
}

.op_product_single_content_block{
	.op_product_buy_table{
		table{
			tr{
				td{
					vertical-align:middle;
					&:nth-child(2){
						padding-left:20px;
						@include screen(mobile){
							padding-left:0px;
						}
					}
					&:nth-child(3){
						width:100px;
						@include screen(mobile){
							min-width:110px;
						}
					}
					@include screen(desktop){
						&:first-child{
							width:330px;
						}
					}
					.quantity{
						input{
							@include font(20px);
							color:$primary;
						}
						@include position(relative);
						.quantity-nav{
							top:0;
							right:0;
							z-index:1;
							padding-right:14px;
							background:#fff;
							@include position(absolute);
							.quantity-button {
								@include font(24px);
								@include line(26px);
								cursor:pointer;
								color:$primary;
							}
							@include screen(tablet){
								padding-right:30px;
							}
							@include screen(mobile){
								padding-right:20px;
							}
						}
					}
					.op_btn_prime{
					position:relative;
						&.loading{
							opacity:0.6;
							pointer-events:none;
							&:before{
								content:'';
								width:15px;
								height:15px;
								border-radius:50%;
								background:transparent;
								border:2px solid #fff;
								border-top:0;
								border-right:0;
								left: 0;
							    top: 0;
							    margin-top: 18px;
							    margin-left: 12px;
							    position:absolute;
							    -ms-animation-iteration-count: infinite;
							    -ms-animation-timing-function: linear;

							    animation-name: spin_load;
							    animation-duration: 4000ms;
							    animation-iteration-count: infinite;
							    animation-timing-function: linear;
							}
						}
					}

				}
			}
			width:100%;

			@include screen(tablet){
				tr{
					display:block;
					td{
						&:first-child{
							display:block;
							width:100%;
							padding-bottom:10px;
							.op_select_box{
								width:100%;
								select{
									width:100%;
								}
								&:after{
									width:12px;
									height:12px;
									margin:18px 15px 0 0;
								}
							}
						}
						width:30%;
					}
				}
			}

			@include screen(mobile){
				tr{
					display:block;
					td{
						&:first-child{
							display:block;
							width:100%;
							padding-bottom:10px;
							.op_select_box{
								width:100%;
								select{
									width:100%;
								}
								&:after{
									width:12px;
									height:12px;
									margin:18px 15px 0 0;
								}
							}
						}
						width:36%;
					}
				}
			}
		}
		.op_select_box{
			@include radius(4px);
			overflow:hidden;
			@include padding-left(10px);
			background:$bg-lightgrey;
			select{
				@include font(16px);
				@include family($oxyrg);
				width:100%;
				height:50px;
				@include border-none;
				background:transparent;
				&:focus{
					outline:none;
					@include box-shadow(0,0,0,0, transparent);
				}
			}
			&:after{
				margin-top: 18px;
			    width: 10px;
			    height: 10px;
			    margin-right: 15px;
			}
		}

		h4{
			@include font(16px);
			color:$primary;
			@include margin(0);
			@include family($oxybd);
			@include paddingall(0,10px,0,10px);
		}

		input[type="number"]{
			@include font(16px);
			@include family($oxyrg);
			width:40px;
			height:50px;
			@include margin-left(10px);
			@include border-none;
			&:focus{
				outline:none;
				@include box-shadow(0,0,0,0, transparent);
			}
		}
		.op_btn_prime{
			width:100px;
			height:50px;
			text-align:center;
			@include line(50px);
			@include radius(5px);
			@include screen(mobile){
				width:100%;
			}
		}
		.added_to_cart.wc-forward{
			display:none;
		}
	}

	.op_product_variations_table{
		@include padding-top(20px);
		@include padding-bottom(20px);
		table{
			width:260px;
			margin-left:20px;

			tr{
				th{
					@include font(14px);
					@include family($oxybd);
					@include padding(10px 0);
				}
				td{
					@include padding(10px 0);
					@include font(14px);
				}
			}
			@include screen(mobile){
				width:100%;
				margin-left:0;
				tr{
					th:nth-child(2),
					td:nth-child(2){
						text-align:right;
					}
					td{
						border-top:1px solid #e5e5e5;
					}
				}
			}
		}
		@include screen(mobile){
			@include padding-top(40px);
			@include padding-bottom(1px);
		}
	}

	@include screen(mobile){
		@include padding-top(40px);  
		.op_product_buy_table{
			.op_mob_single_buy_table{
				ul{
					@include ul-clear;
					@include flex;
					@include flexwrap(wrap);
					li{
						@include padding(10px 0px 10px 0px);
						@include flex;
						@include text-center;
						min-width:25%;
						max-width:25%;
						.op_select_box{
							width:100%;
						}
						.op_btn_prime{
							width:100%;
						}
						h4{
							@include margin(auto);
						}
						span{
							@include margin(auto 0);
						}
						&:first-child{
							min-width:48%;
							max-width:48%;
						}
						&:last-child{
							min-width:100%;
							max-width:100%;
						}
					}
				}
			}
		}
		.op_product_variations_table{
			table{	
				width:100%;
			}	
		}
	}


	@include screen(tablet){
		.op_product_buy_table{
			.op_mob_single_buy_table{
				ul{
					@include ul-clear;
					@include flex;
					@include flexwrap(wrap);
					li{
						@include padding(10px 0px 10px 0px);
						@include flex;
						@include text-center;
						min-width:25%;
						max-width:25%;
						.op_select_box{
							width:100%;
						}
						.op_btn_prime{
							width:150px;
						}
						h4{
							@include margin(auto);
						}
						span{
							@include margin(auto 0);
						}
						&:first-child{
							min-width:48%;
							max-width:48%;
						}
						&:last-child{
							min-width:100%;
							max-width:100%;
						}
					}
				}
			}
		}
		.op_product_variations_table{
			table{	
				width:100%;
			}	
		}
	}
	@include screen(mobile){
		.op_block_title{
			@include font(18px);
			@include line(22px);
			@include margin-bottom(15px);
		}
	}
}




.op_all_models_container{
	table{
		width:100%;
		tr{
			th{
				@include padding(15px);
				@include font(18px);
				@include family($oxybd);
				color:$text-dark;
				&:first-child{
					@include padding-left(0);
				}
				&:last-child{
					@include padding-right(0);
				}
			}
			td{
				@include padding(15px);
				@include font(14px);
				@include family($oxyrg);
				color:$gray-600;
				@include border-bottom(1px, solid, $bg-lightgrey);
				.op_btn_prime{
					@include font(16px);
					height:34px;
					width:100px;
					@include radius(5px);
					@include line(34px);
					position:relative;
					
					&.loading{
						opacity:0.6;
						pointer-events:none;
						&:before{
							content:'';
							width:15px;
							height:15px;
							border-radius:50%;
							background:transparent;
							border:2px solid #fff;
							border-top:0;
							border-right:0;
							left: 0;
						    top: 0;
						    margin-top: 9px;
						    margin-left: 15px;
						    position:absolute;
						    -ms-animation-iteration-count: infinite;
						    -ms-animation-timing-function: linear;

						    animation-name: spin_load;
						    animation-duration: 4000ms;
						    animation-iteration-count: infinite;
						    animation-timing-function: linear;
						}
					}
				}

				&:first-child{
					@include padding-left(0);
					@include family($oxybd);
					color:$text-dark;
				}
				&:last-child{
					@include padding-right(0);
				}
				&:nth-child(2){
					@include family($oxybd);
					color:$text-dark;
				}
				input[type="number"] {
				    font-size: 14px;
				    font-family: oxygenregular;
				    width: 40px;
				    height: 50px;
				    margin-left: 10px;
				    border: 0;
				    &:focus{
				    	outline:none;
				    }
				}
				.quantity{
					display:inline-block;
					width:50px;
					@include position(relative);
					.quantity-nav{
						top:0;
						right:0;
						z-index:1;
						padding-right:1px;
						background:#fff;
						@include position(absolute);
						.quantity-button {
							@include font(24px);
							@include line(26px);
							cursor:pointer;
							color:$primary;
						}
						@include screen(tablet){
							padding-right:0px;
						}
						@include screen(mobile){
							padding-right:0px;
							margin-top:-15px;
							background:transparent;
						}
					}	
					@include screen(mobile){
						width:80px;
						.quantity-nav{

						}
					}
				}

				.added_to_cart {
					display:none;
				}
				@include screen(tablet){
					&:nth-child(5){
						width:116px;
					}
				}
			}

			&:last-child{
				td{
					@include border-none;
				}
			}
		}

		@include screen (tablet){
			tr{
				th{
					@include font(14px);
				}
				td{
					@include font(14px);
				}
			}
		}

		@include screen (tabletrotate){
			tr{
				th{
					@include font(14px);
				}
				td{
					@include font(14px);
				}
			}
		}
	}

	.op_all_mdl_tbl{
		display:none;
	}
	@include screen(mobile){
		wdith:-webkit-calc(100% + 30px);
		wdith:calc(100% + 30px);
		@include margin-left(-15px);
		@include margin-right(-15px);
		table{
			tr{
				position:relative;
				&:nth-child(even){
					background:#f9f9f9;
					&:before{
						content:'';
						width:15px;
						height:100%;
						position:absolute;
						left:0;
						top:0;
						margin-left:-15px;
						background:#f9f9f9;
					}
				}
				@include padding(0 15px);
				display:block;
				margin-bottom:30px;
				&:first-child{
					@include display(none);
				}
				td{
					font-size:14px;
					width:100%;
					display:block;
					padding-left:45%!important;
					@include position(relative);
					&:before{
						content:'';
						left:0;
						top:0;
						font-size:14px;
						width:45%;
						float:left;
						padding-top:12px;
						position:absolute;
					    font-family: oxygenbold;
    					color: #222;
					}
					&:first-child{
						&:before{
							content:'Model';
						}
					}
					&:nth-child(2){
						&:before{
							content:'Price';
						}
					}
					&:nth-child(3){
						&:before{
							content:'Range in LBS';
						}
					}
					&:nth-child(4){
						&:before{
							content:'Cable Sizes';
						}
					}
					&:nth-child(5){
						span{
							display:none;
						}
						input{
							height:auto;
							margin:0;
							background:transparent;
						-webkit-appearance:value;
								appearance:value;
						}
						&:before{
							content:'Quantity';
						}
					}
					&:nth-child(6){
						border-bottom:0;
					}
				}
				&:last-child{
					td{
						border-bottom:1px solid #ebebeb;
					}
				}				
			}
		}
	}
}
.op_all_models_toggle_block{
	width:100%;
	padding:40px 0;
	text-align:center;
	a{
		font-size:18px;
		color:$primary;
		position:relative;
		&:after{
			content:'+';
			right:0;
			top:0;
			color:#484848;
			font-size:24px;
			position:absolute;
			margin-right: -32px;
    		line-height: 28px;
		}
		&.mdl_active{
			&:after{
				content:'_';
				margin-top:-11px;
			}
		}
	}
}
.op_text_woo{
	font-size:14px;
	color:#6d6d6d;
	display:block;
	padding-bottom:10px;
}







@-ms-keyframes spin_load { 
    from { 
        -ms-transform: rotate(0deg); 
    } to { 
        -ms-transform: rotate(360deg); 
    }
}
@-moz-keyframes spin_load { 
    from { 
        -moz-transform: rotate(0deg); 
    } to { 
        -moz-transform: rotate(360deg); 
    }
}
@-webkit-keyframes spin_load { 
    from { 
        -webkit-transform: rotate(0deg); 
    } to { 
        -webkit-transform: rotate(360deg); 
    }
}
@keyframes spin_load { 
    from { 
        transform: rotate(0deg); 
    } to { 
        transform: rotate(360deg); 
    }
}


.skiptranslate goog-te-gadget{
	@include screen(mobile){
		display:none;
	}
}
.parsley-required{
	font-size:14px;
	color:#ff0000;
	display:block;
	margin-top:5px;
}
@include screen(mobile){
	.parsley-errors-list{
		position: absolute;
	    top: 0;
	    right: 0;
	    margin-right: 15px;
	    margin-top: -6px;
	}
	.op_select_box{
		.parsley-errors-list{
			margin-right:5px;
		}
	}
	.op_subscribe_form_block{
		.parsley-errors-list{
			margin-right:20px;
		}
		.op_select_box{
			.parsley-errors-list{
				margin-right:5px;
			}
		}
	}
}





.op_product_of_category_container{
	position:relative;
	.op_gal_nav{
		text-align: center;
	    line-height: 60px;
	    font-size: 45px;
	    color: #ca3c3c;
	    width: 50px;
	    height: 60px;
	    background: transparent;
	    position: absolute;
	    top: 50%;
	    margin-top: -30px;
	    &.op_product_left{
	    	left: 0;
    		margin-left: -50px;
	    }
	    &.op_product_right{
	    	right: 0;
    		margin-right: -50px;	
	    }

	    @include screen(tablet){
    		width: 40px;
    		height: 40px;
    		font-size: 40px;
    		line-height:40px;
    		margin-top: -20px;
    		 &.op_product_left{
		    	left: 0;
	    		margin-left: -15px;
		    }
		    &.op_product_right{
		    	right: 0;
	    		margin-right: -15px;	
		    }
	    	
	    }

	    @include screen(mobile){
    		width: 30px;
    		height: 30px;
    		font-size: 30px;
    		line-height:30px;
    		margin-top: -15px;
    		 &.op_product_left{
		    	left: 0;
	    		margin-left: -10px;
		    }
		    &.op_product_right{
		    	right: 0;
	    		margin-right: -10px;	
		    }
	    	
	    }

	}

	@include screen(mobile){
		.op_product_image_block{
			text-align:center;
			img{
				margin:auto;
			}
		}
	}
}