//footer section scss

//footer about section
.op_footer_about_section{
	width:100%;
	@include flex;
	@include position(relative);


	//text block
	.op_footer_about_text_block{
		@include position(relative);
		background-image:url(../images/about-footer-bg.png);
		@include back-posi(right);
		@include back-size(cover);
		@include back-repeat(no-repeat);
		width:50%;
		max-width:50%;
		@include float-left;
		.op_footer_about_text_block_inner{
			width:570px;
			@include float-right;
			@include paddingall(100px, 40px, 100px, 15px);
		}
		&:after{
			content:'';
			width:100%;
			height:100%;
			left:0;
			top:0;
			@include position(absolute);
			@include linear-grad(#a71252, $primary);
			@include zindex(-1);
		}
		.op_block_title{
			color:$white;
		}
		p{
			@include font(14px);
			@include line(24px);
			@include family($monsterrg);
			color:$white;
		}

		.op_footer_about_button{
			@include padding-top(20px);
		}
	}

	//image block
	.op_footer_about_image_block{
		right:0;
		top:0;
		width:50%;
		height:100%;
		overflow:hidden;
		@include position(absolute);
		img{
			max-width: inherit;
		    width: auto;
		    height: auto;
		    min-height: 100%;
		    min-width: 100%;
		}
	}

	@include screen(mobile){
		.op_footer_about_text_block{
			width:100%;
			max-width:100%;
			@include back-posi(bottom right);
			.op_footer_about_text_block_inner{
				@include text-center;
				width:100%;
				@include paddingall(40px, 15px, 50px, 15px);
			}
		}

		.op_footer_about_image_block{
			@include display(none);
		}
	}

	@include screen(tablet){
		.op_footer_about_text_block{
			.op_footer_about_text_block_inner{
				width:100%;
				@include paddingall(40px, 30px, 50px, 40px);
			}
		}
	}

	@include screen(tabletrotate){
		.op_footer_about_text_block{
			.op_footer_about_text_block_inner{
				width:100%;
				@include padding(50px);
			}
		}
	}
}


//footer links section
.op_footer_links_section{
	@include position(relative);
	@include padding-top(75px);
	@include padding-bottom(150px);
	@include linear-grad (#f6f5f5, $white);

	&:before{
		content:'';
		bottom:0;
		width:100%;
		height:140px;
		@include margin-bottom(12px);
		@include position(absolute);
		background-image:url(../images/footer-links-after.png);
		@include back-size(contain);
		@include back-repeat(no-repeat);
		@include back-posi(center top);
	}

	@include screen (mobile){
		@include padding-top(45px);
		@include padding-bottom(60px);		
		&:before{
			height:40px;
			@include margin-bottom(0px);
		}
	}

	@include screen (tablet){
		@include padding-bottom(100px);		
		&:before{
			height:80px;
			@include margin-bottom(0px);
		}
	}

	@include screen (tabletrotate){
		@include padding-bottom(100px);		
		&:before{
			height:120px;
			@include margin-bottom(0px);
		}
	}
}


//links block
.op_footer_links_block{
	@include padding-right(20px);
	.op_footer_links_heading{
		@include padding-bottom(20px);
		h3{
			@include font(18px);
			color:$gray-600;
			@include padding-bottom(10px);
			@include margin(0);
			@include display(inline-block);
			@include border-bottom(1px, solid, $primary);
			
		}
	}

	.op_footer_links_content{
		@include margin-bottom(40px);
		@include padding-left(20px);
		
		@include screen(tablet){
			@include padding-left(0px);
		}
		@include screen(mobile){
			@include padding-left(0px);
		}
		p{
			@include font(14px);
			@include line(24px);
			@include margin(0);
			@include family($monsterrg);
		}
		ul{
			@include ul-clear;
			li{
				@include padding-left(20px);
				@include position(relative);
				@include font(14px);
				@include line(24px);
				@include margin(0);
				@include margin-bottom(10px);
				@include display(block);
				@include family($monsterrg);
				&:before{
					content:'';
					width:5px;
					height:5px;
					left:0;
					top:0;
					@include margin-top(8px);
					@include radius(50%);
					background:$primary;
					@include position(absolute);
				}		
			}
		}
	}
	@include screen(mobile){
		@include padding-right(0);
		.op_footer_links_heading{
			@include padding-bottom(15px);
			h3{
				@include display(block);
			}
		}
	}

	@include screen(tablet){
		@include padding-right(0);
		.op_footer_links_heading{
			@include padding-bottom(15px);
		}
	}

	@include screen(tabletrotate){
		@include padding-right(0);
	}
}

//copyright section
.op_footer_copyright_section{
	@include paddingall(40px, 0, 40px, 0);
	background-color:$secondary;
	@include text-center;
	p{
		color:$white;
		@include family($oxyrg);
		@include font(14px);
		@include margin(0);
	}
	a{
		color:$white;
		@include family($oxyrg);
		@include font(14px);
		@include margin(0);
		@include transition(0.3s);
		&:hover,
		&:focus{
			color:$gray-600;
		}
	}

	@include screen(mobile){
		@include paddingall(30px, 0, 20px, 0);
	}

	@include screen(tablet){
		@include paddingall(30px, 0, 30px, 0);
	}
}