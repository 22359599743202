  //product landing page scss
.op_product_lp{
	@include padding(50px 0 0px);
}

//title
.op_lp_title{
	color:$primary;
	@include margin-bottom(30px);
	@include font(44px);
	@include screen (mobile){
		@include font(24px);
	}

	@include screen (tablet){
		@include font(36px);
	}
}

//header section
.op_lp_header_section{
	background:#fff;
	.op_lp_header_section_inner{
		@include padding-bottom(20px);
		@include padding-top(20px);

		@include border-bottom(1px, solid, $bg-lightgrey);	
		h3{
			color:$primary;
			@include font(18px);
			@include family($monstersbd);
			@include margin(10px 0 0);
			@include screen(mobile){
				text-align:center;
				color:#515151;
				margin-bottom:20px;
				@include family($monsterrg);
			}
		}
		.op_lp_navigation{
			@include text-right;
			@include screen(mobile){
				text-align:center;
			}
			ul{
				@include ul-clear;
				li{
					@include display(inline-block);
					a{
						color:$gray-800;
						@include font(14px);
						@include family($monsterrg);
						@include padding(4px 15px 4px);
						@include transition(0.3s);
						&:hover,
						&:focus{
							color:$primary;
						}
						@include screen(tablet){
							@include font(14px);
							@include padding(4px 10px 4px);
						}

						@include screen(mobile){
							@include font(12px);
							@include padding(4px 6px 4px);	
						}
					}
					&:last-child{
						a{
							color:$white;
							background:$primary;
							@include display(block);
							@include radius(5px);
							@include padding(6px 20px 4px);
							&:hover,
							&:focus{
								color:$white;
								background:$primary-dark;
							}
						}	
						@include screen(tablet){
							a{
								@include padding(4px 20px 4px);
							}	
						}
						@include screen(mobile){
							a{
								@include padding(4px 10px 4px);
							}	
						}
					}
				}
			}
		}
	}	
}

//lp sections
.op_lp_sections{
	@include padding(70px 0);
	@include screen(mobile){
		@include padding(40px 0);
	}
}

//image with content section
.op_lp_productimage_section{
	@include text-center;
	@include position(relative);
	overflow:hidden;
	
	.op_lp_title{
		@include text-center;
	}

	.op_lp_productmain_image {
		@include padding-top(20px);
		@include margin-bottom(-15px);
		@include screen(mobile){
			@include margin-bottom(-22px);
		}	
		img{
			@include transition(0.1s);
		}
	}
}
.op_lp_inner_flex{
	@include margin(auto 0);
}
//overview section
.op_lp_overview_section{
	background:$white;
	@include position(relative);
	.op_lp_overview_content{
		@include flex;
		height:100%;
		@include screen(mobile){
			position:relative;
			z-index:2;
			text-align:center;
			padding-bottom:30px;
		}
	}

	.op_lp_button_block{
		@include padding-top(30px);
	}
	&:after{
		content:'';
		left:0;
		top:0;
		width:100%;
		height:100px;
		@include margin-top(-60px);
		@include position(absolute);
		@include linear-grad ($white, #f0f0f0);
		@include screen(mobile){
			@include margin-top(-20px);
		}
	}
}

//features
.op_lp_features_section{
	background:$gray-100;
	@include text-center;
	.op_lp_feature_title_block{
		@include margin-bottom(-40px);
		@include position(relative);
		@include zindex(2);

		@include screen(mobile){
			@include margin-bottom(-20px);
		}
		@include screen(tablet){
			@include margin-bottom(0px);
		}
	}	

	.op_lp_feature_img_content_image{
		@include position(relative);
		@include zindex(1);
		@include screen(mobile){
			img{
				width:80%;
				margin:auto;
			}
		}
		.op_lp_feature_img_content_text{
			@include screen(mobile){
				padding-top:40px;
			}
		}
		h3{
			@include margin(0);
			@include font(16px);
			@include position(absolute);
			&.op_indicate_right{
				top:0;
				left:0;
				@include padding-right(220px);
				&:after{
					content:'';
					width:5px;
					height:5px;
					right:0;
					top:0;
					@include margin-top(8px);
					@include radius(50%);
					background:$primary;
					@include position(absolute);
				}
				&:before{
					content:'';
					width:180px;
					height:1px;
					right:0;
					top:0;
					@include margin-top(10px);
					background:$primary;
					@include position(absolute);
				}
			}
			
			&.op_lp_feature07{
				@include margin-top(245px);
				@include margin-left(120px);
			} 
			&.op_lp_feature06{
				@include margin-top(325px);
				@include margin-left(65px);
				@include padding-right(280px);
				&:before{
					width:250px;
				}
			} 
			&.op_lp_feature05{
				@include margin-top(400px);
				@include margin-left(0px);
			}

			&.op_indicate_left{	
				right:0;
				top:0;
				@include padding-left(220px);
				&:after{
					content:'';
					width:5px;
					height:5px;
					left:0;
					top:0;
					@include margin-top(8px);
					@include radius(50%);
					background:$primary;
					@include position(absolute);
				}
				&:before{
					content:'';
					width:180px;
					height:1px;
					left:0;
					top:0;
					@include margin-top(10px);
					background:$primary;
					@include position(absolute);
				}
			} 

			&.op_lp_feature04{
				@include margin-top(370px);
				@include margin-right(100px);
			}

			&.op_lp_feature03{
				@include margin-top(295px);
				@include margin-right(-8px);
			}
			&.op_lp_feature02{
				@include margin-top(230px);
				@include margin-right(-8px);
			}
			&.op_lp_feature01{
				@include margin-top(160px);
				@include margin-right(30px);
			}

			@include screen(mobile){
				color:#434343;
				@include font(14px);
				position:relative;

				&.op_indicate_right{
					padding-bottom:10px;
					padding-left:90px;
					padding-right:0px;
					text-align:left;
					&:before{
						right:inherit;
						left:0;
						width:85px;
						margin-left:-15px;
					}
					&:after{
						right:inherit;
						left:0;
						margin-left:70px;
					}
				}

				&.op_lp_feature07{
					@include margin-top(0px);
					@include margin-left(0px);
				} 
				&.op_lp_feature06{
					@include margin-top(0px);
					@include margin-left(0px);
					@include padding-right(0px);
					&:before{
						width:70px;
					}
				} 
				&.op_lp_feature05{
					@include margin-top(0px);
					@include margin-left(0px);
				}

				&.op_indicate_left{
					padding-bottom:10px;
					text-align:left;
					padding-left:90px;
					&:before{
						width:85px;
						margin-left:-15px;
					}
					&:after{
						margin-left:70px;
					}
				}

				&.op_lp_feature04{
					@include margin-top(0px);
					@include margin-right(0px);
				}

				&.op_lp_feature03{
					@include margin-top(0px);
					@include margin-right(0px);
				}
				&.op_lp_feature02{
					@include margin-top(0px);
					@include margin-right(0px);
				}
				&.op_lp_feature01{
					@include margin-top(0px);
					@include margin-right(0px);
				}

			}
		}

		@include screen(tablet){

			img{
				width:340px;
			}
			
			h3{
				@include font(14px);
				&.op_indicate_right{
					top:0;
					left:0;
					@include padding-right(120px);
					&:after{
						content:'';
						width:5px;
						height:5px;
						right:0;
						top:0;
						@include margin-top(8px);
						@include radius(50%);
						background:$primary;
						@include position(absolute);
					}
					&:before{
						content:'';
						width:100px;
						height:1px;
						right:0;
						top:0;
						@include margin-top(10px);
						background:$primary;
						@include position(absolute);
					}
				}
				
				&.op_lp_feature07{
					@include margin-top(155px);
					@include margin-left(50px);
				} 
				&.op_lp_feature06{
					@include margin-top(200px);
					@include margin-left(30px);
					@include padding-right(150px);
					&:before{
						width:130px;
					}
				} 
				&.op_lp_feature05{
					@include margin-top(275px);
					@include margin-left(0px);
				}

				&.op_indicate_left{	
					right:0;
					top:0;
					@include padding-left(120px);
					&:after{
						content:'';
						width:5px;
						height:5px;
						left:0;
						top:0;
						@include margin-top(8px);
						@include radius(50%);
						background:$primary;
						@include position(absolute);
					}
					&:before{
						content:'';
						width:100px;
						height:1px;
						left:0;
						top:0;
						@include margin-top(10px);
						background:$primary;
						@include position(absolute);
					}
				} 

				&.op_lp_feature04{
					@include margin-top(235px);
					@include margin-right(50px);
				}

				&.op_lp_feature03{
					@include margin-top(188px);
					@include margin-right(-25px);
				}
				&.op_lp_feature02{
					@include margin-top(145px);
					@include margin-right(-25px);
				}
				&.op_lp_feature01{
					@include margin-top(92px);
					@include margin-right(15px);
				}
			}
		}
	}
}

.op_lp_battery_section {
	@include screen(mobile){
		padding:60px 0;
		text-align:center;
		.op_lp_title{
			margin-bottom:10px;
		}
	}
}

//battery section
.op_lp_battery_content_block{
	@include flex;
	height:100%;
	.op_lp_ballery_block{
		@include position(relative);
		@include display(inline-block);
		.op_battery_fill{
			top:0;
			left:0;
			width:-webkit-calc(100% - 25px);
			width:calc(100% - 25px);
			height:-webkit-calc(100% - 4px);
			height:calc(100% - 4px);
			@include margin-left(5px);
			@include margin-top(2px);
			@include position(absolute);
			overflow:hidden;
			.op_battery_fillinner{
				@include flex;
				height:100%;
				width:100%;
				@include position(relative);
				@include linear-grad (#a81352, $primary);
				&:before{
					content:'';
					width:200%;
					height:100%;
					right:100%;
					top:0;
					@include position(absolute);
					@include linear-grad (#a81352, $primary);
				}
			}
			@include screen(mobile){
				width:-webkit-calc(100% - 20px);
				width:calc(100% - 20px);
			}
		}
	}
	.op_lp_ballery_block{
		@include margin-bottom(50px);
		@include screen(mobile){
			margin-bottom:25px;
			width:160px;
		}
	}
	.op_lp_button_block{
		@include padding-top(20px);
	}
}
.op_lp_battery_content_image {
	//@include padding(0px 0 100px);
	img{
		//@include margin-bottom(50px);
	}
}
//accordion section
.lp_accordion_section{
	@include padding(100px 0 70px);
	@include position(relative);
	background-image:url(../images/accordion.jpg);
	@include back-size(cover);
	@include back-posi(center top);
	@include back-repeat(no-repeat);
	.row{
		@include margin(0 -30px 0);
		.col-md-6{
			@include padding(0 30px 0);
		}
	}
	.op_lp_title{
		color:$white;
		@include text-center;
		@include margin-bottom(80px);
	}
	.card{
		background:none;
		@include border-none;
		@include margin-bottom(40px);
		@include padding-left(30px);
		.card-header{
			@include padding(0);
			background-color:transparent;
			@include border-none;
			.btn-link{
				color:$white;
				@include padding(0);
				@include font(18px);
				@include family($oxybd);
				@include position(relative);
				white-space:normal;
				@include text-left;
				&:before{
					content: '';
				    left: 0;
				    top:0;
				    width: 20px;
				    height: 1px;
				    @include margin-left(-39px);
				    @include margin-top(12px);
				    @include position(absolute);
				    background: $white;
				}

				&:after{
					content: '';
				    left: 0;
				    top:0;
				    width: 1px;
				    height: 20px;
				    opacity:0;
				    @include margin-left(-30px);
				    @include margin-top(3px);
				    @include position(absolute);
				    background: $white;
				}
				&.collapsed{
					&:after{
						opacity:1;
					}
				}

				&:hover,
				&:focus{
					text-decoration:none;
				}
				@include screen (mobile){
					@include font(16px);
					@include family($monstermd);
				}
			}	
		}

		.card-body{
			@include padding(15px 0 0);
			p{
				color:$white;
				@include margin(0);
			}
		}
	}

	@include screen(mobile){
		@include padding(50px 0 30px);
		.op_lp_title{
			@include margin-bottom(20px);
		}

		.row{
			@include margin(0 -15px 0);
			.col-md-6{
				@include padding(0 15px 0);
			}
		}

		.card{
			@include margin-bottom(30px);
			.card-header{
				.btn-link{
					&:before{
					    @include margin-left(-29px);
					}

					&:after{
						content: '';
					    @include margin-left(-20px);
					}    
				}
			}
		}		
	}


	@include screen(tablet){
		@include padding(60px 0 40px);
		.op_lp_title{

			@include margin-bottom(40px);
		}

		.row{
			@include margin(0 -20px 0);
			.col-md-6{
				@include padding(0 20px 0);
			}
		}

		.card{
			@include margin-bottom(40px);
			.card-header{
				.btn-link{
					&:before{
					    @include margin-left(-29px);
					}

					&:after{
						content: '';
					    @include margin-left(-20px);
					}    
				}
			}
		}		
	}
}


.op_animate_heading{
	.op_lp_title{
		overflow: hidden;
	    white-space: nowrap; 
	  	margin: 0 auto; 
	  	padding:15px 0;
	  	-webkit-animation: typing 6s steps(60, end);
	  			animation: typing 6s steps(60, end);
	}
}

/* The typing effect */
@-webkit-keyframes typing {
	from { width: 0 }
  	to { width: 100% }	
}
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}


.hidden_overflow{
	overflow:hidden;
}

.parallax-image {
	min-height:500px;
	position:relative;
	img{
		position:absolute;	
		@include screen(mobile){
			position:relative;
			width:60%;
			margin:auto;
		}
		
	}
	@include screen(mobile){
		min-height:inherit;
		padding-top:40px;
	}
}