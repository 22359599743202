//about page scss
.op_hero_panel_section{
	@include text-center;
	@include padding(95px 0);
	@include back-size(100%);
	@include back-attach(fixed);
	@include position(relative);
	-webkit-background-position:left top;
			background-position:left top;
	-webkit-background-repeat:no-repeat;
			background-repeat:no-repeat;
	z-index:0;
	&:after{
		content:'';
		width:100%;
		height:100%;
		left:0;
		top:0;
		z-index:-1;
		opacity:0.9;
		@include linear-grad(#a71252, $primary);
		@include position(absolute);
	}
	h2{
		@include font(30px);
		color:#fff;
		@include margin(0 0 30px);
		@include padding(0 10px);
		@include display(inline-block);
		@include position(relative);
		&:before{
			content:'[';
			top:0;
			left:0;
			margin-left:-10px;
			color:#fff;
			@include font(30px);
			position:absolute;
		}
		&:after{
			content:']';
			top:0;
			right:0;
			margin-right:-10px;
			color:#fff;
			@include font(30px);
			position:absolute;
		}
	}
	p{
		font-size:14px;
		line-height:24px;
		margin:0;
		@include family($monsterrg);
		color:#fff;
		a{
			@include family($monsterbd);
			color:#fff;	
			&:hover,
			&:focus{
				color:#ddd;
			}
		}
	}

	@include screen(mobile){
		@include back-size(cover);
		@include back-attach(inherit);
		@include padding(40px 0);
		h2{
			@include font(24px);
			@include margin(0 0 20px);
		}	
	}
	&.support_hero{
		height:325px;
		text-align:left;
		@include padding(55px 0);

		h2{
			margin-bottom:10px;
			padding:0;
			&:after,
			&:before{
				display:none;
			}
		}

		.op_select_box.service_select_box{
			height:60px;
			background:#fff;
			select{
				width:100%;
				border:0;
				height:60px;
				padding:0 20px;
				&:focus{
					outline:none;
				}
			}
		}
		.container{
			margin-top:150px;
		}
		@include screen(mobile){
			height:auto;
			@include padding(30px 0);
			.container{
				margin-top:50px;
			}	
			.op_service_content{
				padding-bottom:30px;
			}
		}
		@include screen(tablet){
			height:auto;
			@include padding(40px 0);
			.container{
				margin-top:100px;
			}
		}
	}

	&.search_page{
		@include padding(30px 0);
		.container{
			margin-top:100px;
		}
		h2{
			margin:0;
			&:after,
			&:before{
				display:none;
			}
		}
	}
}



.op_emblem_section{
	@include padding(50px 0);
	.op_emblem_image{
		position:relative;
		&:after{
			content:'';
			width:1px;
			height:100%;
			top:0;
			right:0;
			margin-right:-35px;
			position:absolute;
			background:#efc4c4;
		}
	}
	.op_emblem_content_block{
		padding-left:40px;
		@include flex;
		height:100%;
		p{
			margin:auto 0;
			@include family($monsterrg);
		}
	}

	@include screen(mobile){
		@include text-center;
		.op_emblem_image{
			&:after{
				margin-right:-15px;
			}
		}
		.op_emblem_content_block{
			padding-left:10px;
		}
	}

	@include screen(mobile){
		@include text-center;
		.op_emblem_image{
			&:after{
				display:none;
			}
			margin-bottom:30px;
		}
		.op_emblem_content_block{
			padding-left:0;
		}
	}
}



.op_flip_flop_section {
	width:100%;
	overflow:hidden;
	padding-bottom:30px;
	.col-md-6,
	.col-md-4{
		margin-bottom:30px;
	}
	&.op_service_flip_flop{
		padding-top:50px;
	}
}
.op_flip_flop_block{
	@include text-center;
	@include padding(40px 30px);
	//background:#fcfcfc;
	@include flex;
	height:100%;
	cursor:pointer;
	//border:1px solid #f7f6f6;
	//-webkit-box-shadow: 0px 4px 2.94px 0.06px rgba(0, 0, 0, 0.07);  
	//		box-shadow: 0px 4px 2.94px 0.06px rgba(0, 0, 0, 0.07);  

	@include screen(mobile){
		@include padding(20px 15px);
	}
	.op_flip_flop_icon{
		height:60px;
		margin-bottom:25px;
	}
	.op_flip_flop_content{
		p{
			@include family($monsterrg);
			@include margin(0);	
		}
	}
	h2{
		@include font(24px);
		@include family($monsterrg);
		@include margin(0 0 30px);
		@include screen(tablet){
			@include font(18px);
			@include margin(0 0 20px);
		}
		@include screen(mobile){
			@include font(20px);
			@include margin(0 0 20px);
		}
	}

	h3{
		@include font(14px);
		color:$primary;
		@include family($monsterrg);
		@include margin(50px 0 0);	
		@include screen(tablet){
			@include margin(30px 0 0);
		}
		@include screen(mobile){
			@include margin(30px 0 0);
		}
	}

	.op_flip_content_block{
		opacity:0;
		left:0;
		top:0;
		width:100%;
		height:100%;
		@include flex;
		@include text-center;
		@include position(absolute);
		-webkit-animation-duration: 0.3s;
		    	animation-duration: 0.3s;
		    -webkit-animation-name: anim-close;
    				animation-name: anim-close;
		-webkit-animation-fill-mode: forwards;
		    	animation-fill-mode: forwards;

		.op_flip_content_block_inner{	
			margin:auto;
			padding:20px;
			ul{
				@include ul-clear;
				li{
					display:block;
					span{
						font-size:18px;
						line-height:36px;
						@include family($monsterrg);
						display:inline-block;
						@include position(relative);
						&:before{
							content:'';
							width:3px;
							height:3px;
							left:0;
							top:0;
							margin-left:-10px;
							margin-top:15px;
							border-radius:50%;
							background:$primary;
							position:absolute;
						}
						&:after{
							content:'';
							width:3px;
							height:3px;
							right:0;
							top:0;
							margin-right:-10px;
							margin-top:17px;
							border-radius:50%;
							background:$primary;
							position:absolute;
						}
						@include screen(mobile){
							font-size:14px;
							line-height:30px;
						}
					}
				}
			}
		}	
	}
	.op_flip_flop_block_inner{
		@include transition(0.3s);
	}

	&.op_flip_active{
		.op_flip_flop_block_inner{
			opacity:0;
		}
		.op_flip_content_block{
			opacity:1;
			-webkit-animation-duration: 0.3s;
		    animation-duration: 0.3s;
		    -webkit-animation-fill-mode: forwards;
		    animation-fill-mode: forwards;
			-webkit-animation-name: anim-open;
					animation-name: anim-open;
			@include screen(tablet){
				p{
					font-size:12px;
				}
			}
		}
	}

	&.op_flip_blank{
		border:0;
		padding:20px 0;
		background:transparent;
		-webkit-box-shadow:none;
				box-shadow:none;
		.op_flip_content_block{
			h4{
				font-size:14px;
				@include margin(0);
				@include family($monsterbd);
			}
			p{
				@include margin(0);
				@include family($monsterrg);	
			}
		}
	}
}

.op_flip_blank{
	.op_flip_flop_content_inner{
		position:relative;
		.op_flip_content_block_inner{
			padding:0 20px 20px;
			@include screen (tablet){
				padding:0;
			}
		}
		.op_flip_service_block{
			@include transition(0.3s);
		}
	}
	&.op_flip_active{
		.op_flip_flop_block_inner{
			opacity:1;
			.op_flip_flop_content_inner{
				.op_flip_service_block{
					opacity:0;		
				}
			}	
		}
	}
}

@-webkit-keyframes anim-open {
	0% { opacity: 0; -webkit-transform: translate3d(0, 50px, 0); }
	100% { opacity: 1; -webkit-transform: translate3d(0, 0, 0); }
}

@keyframes anim-open {
	0% { opacity: 0; -webkit-transform: translate3d(0, 50px, 0); transform: translate3d(0, 50px, 0); }
	100% { opacity: 1; -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }
}
@-webkit-keyframes anim-close {
	0% { opacity: 1; -webkit-transform: translate3d(0, 0, 0); }
	100% { opacity: 0; -webkit-transform: translate3d(0, 50px, 0); }
}

@keyframes anim-close {
	0% { opacity: 1; -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }
	100% { opacity: 0; -webkit-transform: translate3d(0, 50px, 0); transform: translate3d(0, 50px, 0); }
}







.op_support_downloads_outer{
	.op_documents_download_section{
		padding:80px 0 60px;
		@include screen (mobile){
			padding:40px 0 30px;
		}
	}
	.op_heading_block{
		display:none;
	}
}

.op_support_section_heading_block{
	text-align:center;
	.op_heading_block{
		padding-top:70px;
		border-top:1px solid #ebebeb;
		display:inline-block;
		@include screen(mobile){
			padding-top:40px;
		}
	}
}

.op_supplier_sidebar{
	h3{
		font-size:24px;
		color:$primary;
		margin-top:0;
		text-align:center;
		margin-bottom:20px;
	}
}
.supplier_carousel {
	.slick-dots{
		padding:0;
		margin:0;
		padding-top:6px;
		text-align:center;
		li{
			margin:0 5px;
			display:inline-block;
			button{
				padding:0;
				width:10px;
				height:10px;
				border-radius:50%;
				font-size:0;
				border:0;
				background:#ebebeb;
			}
			&.slick-active{
				button{
					background:$primary;
				}
			}
		}
	}
	.op_product_block {
		padding:10px 20px;
		.op_new_product_tag{
			margin-top:-6px;
		}
	}
	@include screen(mobile){
		padding-bottom:40px;
		.slick-slide{
			img{
				margin:auto;
			}
		}
	}
}


.op_suppliers_page{
	.op_text_container{
		padding-left:30px;
		border-left:1px solid #efc4c4;
		@include screen(mobile){
			padding-left:0px;
			border-left:0;
		}
	}
}










.sticky-top{
	z-index:5!important;
}



//news page
.op_news_page{
	text-align:center;
	padding:100px 0;
	@include screen(tablet){
		padding:80px 0;
	}

	@include screen(mobile){
		padding:50px 0;
	}
	ul{
		text-align:left;
		width:100%!important;
		@include flex;
		@include ul-clear;
		@include flexwrap(wrap);
		li{
			width:33%;
			float:left;
			list-style:none;
			padding:50px 30px;
			border-top: 1px solid #ebebeb;
    		border-right: 1px solid #ebebeb;
    		-webkit-transition:0.3s;
    				transition:0.3s;

    		@include screen(tablet){
				padding:30px 15px;
			}

    		@include screen(mobile){
				width:100%;
				padding:30px 0;
				border-right:0!important;
			}

    		&:first-child,
    		&:nth-child(2),
    		&:nth-child(3) {
			    border-top: 0 solid transparent;
			    @include screen(mobile){
					border-top: 1px solid #ebebeb;
					&:first-child{
						border-top: 0 solid transparent;
					}
				}
			}
			&:nth-of-type(3n) {
			    border-right: 0 solid transparent;
			}
			&:hover,
			&:focus{
				-webkit-box-shadow: 0px 2px 7.36px 0.64px rgba(1, 1, 1, 0.09); 
						box-shadow: 0px 2px 7.36px 0.64px rgba(1, 1, 1, 0.09); 
			}
			.op_news_title_block{
				padding-bottom:20px;
				.op_news_date{
					font-size:12px;
					color:#717171;
					padding-bottom:10px;
				}
				a{
					font-size:18px;
					line-height:24px;
					color:$primary;
					@include family($monstersbd);
				}
			}
			.op_news_content_block{
				font-size:14px;
				line-height:24px;
				
				.article-body{
					a, a:hover,a:focus{
						color:#6d6d6d;
						height:auto;
						line-height:inherit;
						padding:0;
						display:initial;
						background:transparent;
						text-transform:inherit;
					}
				}
				a{
					font-size: 14px;
				    color: #fff;
				    height: 40px;
				    line-height: 40px;
				    border: 0;
				    padding: 0 30px;
				    font-family: montserratregular;
				    display: inline-block;
				    background: #ca3c3c;
				    text-align: center;
				    -webkit-transition: 0.3s;
				    -webkit-transition: 0.3s;
				    transition: 0.3s;
				    text-transform:capitalize;
				    margin-top:30px;
				    &:hover,
				    &:focus{
				    	color: #fff;
    					background: #760000;
				    }
				}
			}	
		}
	}

	.easyPaginateNav{
		margin-top:60px;
		padding:20px 0;
		text-align:center;
		display:inline-block;
		width:auto!important;
		border:1px solid #ebebeb;
		border-radius:8px;
		a{
			font-size:18px;
			color:#a2a2a2;
			@include family($monstersbd);
			display: inline-block;
			@include transition(0.3s);
    		padding: 0 15px;
    		&.current,
    		&:hover,
    		&:focus{
    			color:$primary;
    			text-decoration:none;
    		}
		}
	}
}



//search page
.search_content_row_container{
	.search_row{
		padding:60px 0;
		border-bottom:1px solid #ebebeb;
		@include screen(mobile){
			padding:40px 0;
		}

		.search_page_content{
			h3{
				font-size:18px;
				color:$primary;
				margin:0;
				margin-bottom:10px;
				@include family($monstersbd);
			}
			p{
				font-size:14px;
				line-height:24px;
			}
		}
		.search_side{
			@include flex;
			height:100%;
			a{
				margin:auto;
				@include screen(mobile){
					margin-left:0;
				}
			}
		}
	}
}


.op_search_not_found{
	text-align:center;
	padding:80px 0;
	h2{
		font-size:30px;
		color:#3f3f3f;
		margin-bottom:12px;
		@inclue family($monsterrg);
	}
	h3{
		font-size:18px;
		color:#9e9e9e;
		@inclue family($monsterrg);
	}

	.op_search_image_block{
		margin-top:60px;
	}
}