//home product section scss
.op_products_section_home{
	@include padding-top(120px);
	@include padding-bottom(120px);

	&.op_single_product_page{
		@include padding-top(80px);
		@include padding-bottom(80px);

		@include screen(mobile){
			@include padding-top(30px);
			@include padding-bottom(30px);
		}

		@include screen(tablet){
			@include padding-top(50px);
			@include padding-bottom(50px);
		}

		@include screen(tabletrotate){
			@include padding-top(50px);
			@include padding-bottom(50px);
		}
	}

	.col-md-4{
		@include padding(0);
		@include border-top(1px, solid, $bg-lightgrey);
		@include border-right(1px, solid, $bg-lightgrey);
		&:nth-of-type(3n){
			@include border-right(0, solid, transparent);
		}
		&:first-child,
		&:nth-child(2),
		&:nth-child(3){
			@include border-top(0, solid, transparent);
		}
	}

	@include screen(mobile){
		@include padding-top(50px);
		@include padding-bottom(50px);
		.col-md-4{
			@include border-none;
			@include border-top(1px, solid, $bg-lightgrey);
			@include border-right(0px, solid, $bg-lightgrey);
			&:first-child{
				@include border-top(1px, solid, tranaparent);
			}	
			&:nth-child(2),
			&:nth-child(3){
				@include border-top(1px, solid, $bg-lightgrey);
			}
		}
	}

	@include screen(tablet){
		@include padding-top(80px);
		@include padding-bottom(80px);
	}
}

.op_product_of_category_container {
	@include screen(tablet){
		@include padding-top(20px);
	}
	.col-lg-4{
		@include padding(0);
		@include border-top(1px, solid, $bg-lightgrey);
		@include border-right(1px, solid, $bg-lightgrey);
		&:nth-of-type(3n){
			@include border-right(0, solid, transparent);
		}
		&:first-child,
		&:nth-child(2),
		&:nth-child(3){
			@include border-top(0, solid, transparent);
		}
	}

	.col-lg-3{
		@include padding(0);
		@include border-top(1px, solid, $bg-lightgrey);
		@include border-right(1px, solid, $bg-lightgrey);
		&:nth-of-type(4n){
			@include border-right(0, solid, transparent);
		}
		&:first-child,
		&:nth-child(2),
		&:nth-child(3),
		&:nth-child(4){
			@include border-top(0, solid, transparent);
		}
	}
	.op_tensionmeter_carousel{
		.col-lg-4{
			@include padding(0);
			@include border-top(0px, solid, $bg-lightgrey);
			@include border-right(1px, solid, $bg-lightgrey);
			&:nth-of-type(3n){
				@include border-right(0px, solid, $bg-lightgrey);
			}
			&:nth-of-type(4n){
				@include border-right(1px, solid, $bg-lightgrey);
			}
			&:first-child,
			&:nth-child(2),
			&:nth-child(3){
				@include border-top(0, solid, $bg-lightgrey);
			}
		}

		.col-lg-3{
			@include padding(0);
			@include border-top(0px, solid, $bg-lightgrey);
			@include border-right(1px, solid, $bg-lightgrey);
			&:nth-of-type(3n){
				@include border-right(0px, solid, $bg-lightgrey);
			}
			&:first-child,
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4){
				@include border-top(0, solid, $bg-lightgrey);
			}
		}

		@include screen(tablet){
			.col-lg-4{
				border:0!important;
				&:nth-of-type(2n){
					border-right:1px solid $bg-lightgrey!important;
				}
			}

			.col-lg-3{
				border:0!important;
				&:nth-of-type(2n){
					border-right:1px solid $bg-lightgrey!important;	
				}
			}
		}
	}

	@include screen(tablet){
		.col-md-6,
		.col-lg-3,
		.col-lg-4{
			@include padding(40px 15px);
			@include border-top(1px, solid, $bg-lightgrey);
			@include border-right(1px, solid, $bg-lightgrey);
			&:nth-of-type(2n){
				@include border-right(0, solid, transparent);
			}
			&:first-child,
			&:nth-child(2){
				@include border-top(0, solid, transparent);
			}
			&:nth-child(3),
			&:nth-child(4){
				@include border-top(1px, solid, $bg-lightgrey);
			}
			.op_product_block{
				&.op_inner_product{
					margin-bottom:0;
				}
			}
		}
	}
	@include screen(mobile){
		.col-lg-4,
		.col-lg-3{
			border:0;
		}
	}
}

//products block
.op_product_block{
	@include text-center;
	@include paddingall(40px, 20px, 40px, 20px);
	.op_product_image_block{
		@include position(relative);
		@include margin-bottom(20px);
		@include screen(upmobile){
			height:160px;
			width:100%;
			@include flex;
			img{
				max-height:100%;
				@include margin(auto);
				image-rendering: -moz-crisp-edges; /* Firefox /
				image-rendering: -o-crisp-edges; / Opera /
				image-rendering: -webkit-optimize-contrast;/ Webkit (non-standard naming) /
				image-rendering: crisp-edges;
				-ms-interpolation-mode: nearest-neighbor; / IE (non-standard property) */
			}
		}

		.op_new_product_tag{
			top:0;
			left:50%;
			margin-left:-40px;
			margin-top:-25px;
			width:80px;
			height:22px;
			font-size:10px;
			line-height:22px;
			border-radius:3px;
			@include family($monsterrg);
			color:#fff;
			text-transform:uppercase;
			background:$primary;
			position:absolute;
		}

		a{
			width:100%;
		}
	}
	.op_product_content_block{
		.op_product_info_block{
			min-height:60px;
			@include margin-bottom(20px);	
			a{
				color:$text-dark;
				@include font(18px);
				@include family($oxybd);
				@include display(block);
			}
			h2{
				color:$gray-600;
				@include font(14px);
				@include family($oxybd);
				@include text(uppercase);
				.op_product_type{
					@include margin-right(5px);
					@include padding-right(9px);
				}
				.op_product_price{
					color:$primary;
				}
			}
		}
	}	

	&.op_inner_product{
		@include padding(0 15px);

		@include screen(mobile){
			@include padding-bottom(40px);
			@include margin-bottom(40px);
			border-bottom:1px solid #e8e8e8;
		}

		@include screen(tablet){
			@include margin-bottom(50px);
		}
	}

	@include screen(mobile){
		.op_product_content_block{
			.op_product_info_block{
				a{
					@include font(16px);
				}
				h2{
					@include font(16px);
				}
			}
		}
	}

	@include screen(tablet){
		.op_product_content_block{
			.op_product_info_block{
				a{
					@include font(16px);
					@include line(20px);
					@include margin-bottom(10px);
				}
				h2{
					@include font(16px);
				}
			}
		}
	}

	@include screen(tabletrotate){
		.op_product_content_block{
			.op_product_info_block{
				a{
					@include font(16px);
					@include line(20px);
					@include margin-bottom(10px);
				}
				h2{
					@include font(16px);
				}
			}
		}
	}
}


.op_product_of_category_container{
	.row{	
		.col-md-6{
			&:last-child{
				.op_product_block.op_inner_product{
					border-bottom:0;
					@include padding-bottom(20px);
					@include margin-bottom(10px);
				}	
			}
		}	
	}		
}