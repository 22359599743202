//banner section scss
.op_banner_section{
	@include padding-top(50px);
	@include padding-bottom(100px);
	overflow:hidden;
	@include position(relative);
	
	@include screen(mobile){
		@include padding-top(40px);
		@include padding-bottom(40px);
	}

	.op_banner_parallax{
		left:0;
		top:0;
		height:100%;
		overflow:hidden;
		width: -webkit-calc(100% + 400px);
		width: calc(100% + 400px);
		@include margin-left(-200px);
		@include border-bottom-left-radius(40%);
		@include border-bottom-right-radius(40%);
		@include linear-grad(#a71252, $primary);
		@include position(absolute);
		@include zindex(1);
		.op_banner_parallax_inner{
			width:100%;
			height:100%;
			left:0;
			top:0;
			@include position(absolute);
			background-image:url(../images/banner-bg.png);
			@include back-repeat(no-repeat);
			@include back-size(contain);
			@include back-posi(center top);
			@include zindex(-1);
			@include screen(mobile){
				@include back-size(cover);
			}
		}

		@include screen(mobile){
			&:after{
				@include back-size(cover);
			}
		}

		@include screen(tablet){
			&:after{
				@include back-size(cover);
			}
		}

		@include screen(tabletrotate){
			&:after{
				@include back-size(cover);
			}
		}
	}
	.op_banner_content_block{
		@include position(relative);
		@include zindex(2);

		.op_banner_product_image{
			@include padding-right(20px);
			@include text-right;
			@include screen(mobile){
				@include padding-right(0px);
				@include text-center;
				@include margin-bottom(15px);
				img{
					max-width:200px;
					max-height:150px;
					width:auto;
					height:auto;
					margin:auto;
				}
			}

			@include screen(tablet){
				@include padding-right(0px);
				img{
					max-width:200px;
				}
			}

			@include screen(tabletrotate){
				@include padding-right(0px);
				img{
					max-width:220px;
				}
			}
		}
		
		.op_banner_product_content_block{
			@include flex;
			height:100%;
			width:100%;
			
			.op_banner_product_content_block_inner{
				width:100%;
				@include marginall(auto, 0, auto, 0);
				h1{
					@include font(36px);
					@include margin(0);
					@include family($oxybd);
					@include line(36px);
					color:$white;
				}
				a{
					@include font(16px);
					@include margin-top(10px);
					@include display(inline-block);
					color:$white;	
					@include padding-right(30px);
					@include position(relative);
					&:after{
						content:'';
						width:10px;
						height:10px;
						top:0;
						right:0;
						@include margin-top(7px);
						@include border-top(1px, solid, $white);
						@include border-right(1px, solid, $white);
						@include position(absolute);
						@include transform(rotate(45deg));
						@include transition(0.3s);
					}
					&:hover,
					&:focus{
						&:after{
							@include margin-right(-10px);
						}
					}
				}
			}

			@include screen(mobile){
				@include text-center;
				.op_banner_product_content_block_inner{
					h1{
						@include font(24px);
						@include line(26px);
					}
					a{
						@include font(14px);
					}
				}
			}

			@include screen(tablet){
				.op_banner_product_content_block_inner{
					@include text-left;
					h1{
						@include font(30px);
						@include line(32px);
					}
					a{
						@include font(14px);
					}
				}
			}
		}
	}
	.slick-slider{
		@include position(relative);
		@include zindex(5);
	}
}




.op_banner_section{
	position:relative;
	.op_gal_nav{
		font-size:45px;
		width:40px;
		height:40px;
		text-align:center;
		line-height:40px;
		top:50%;
		margin-top:-20px;
		position:absolute;
		z-index:5;
		color:#fff;
		&.op_bnr_left{
			left:0;
		}
		&.op_bnr_right{
			right:0;
		}
		&.slick-disabled{
			display:none!important;
		}
		@include screen(mobile){
			font-size:24px;
			width:30px;
			height:30px;
			line-height:30px;
			margin-top:-15px;
			&.op_bnr_left{
				left:0;
				margin-left:-10px;
			}
			&.op_bnr_right{
				right:0;
				margin-right:-10px;
			}	
		}
	}
}