//common scss
.op_page_content_block{
	@include padding-top(50px);
	@include padding-bottom(50px);

	@include screen(mobile){
		@include padding-top(30px);
		@include padding-bottom(30px);		
	}
}



//list
.op_dot_list{		
	ul{
		@include ul-clear;
		li{
			@include padding-left(20px);
			@include position(relative);
			@include font(14px);
			@include line(24px);
			@include margin(0);
			@include margin-bottom(10px);
			@include display(block);
			@include family($monsterrg);
			&:before{
				content:'';
				width:5px;
				height:5px;
				left:0;
				top:0;
				@include margin-top(8px);
				@include radius(50%);
				background:$primary;
				@include position(absolute);
			}		
		}
	}

	&.op_main_festures_list{
		ul{
			@include flex;
			@include flexwrap(wrap);
			@include margin-left(-30px);
			@include margin-right(-30px);
			@include padding-left(40px);
			li{
				max-width:49%;
				min-width:49%;
				@include padding-left(30px);
				@include padding-right(30px);
				@include margin-bottom(20px);
			}

			@include screen(mobile){
				@include margin-left(0px);
				@include margin-right(0px);
				@include padding-left(0px);
				li{
					@include padding-left(0px);
					@include padding-right(0px);
					@include padding-left(20px);
				}
			}
		}


		@include screen(tabletrotate){
			ul{
				display:table;
				width:100%;

				li{
					float:left;
					max-width:50%;
					min-width:50%;
				}
			}
		}	

		@include screen(tablet){
			ul{
				display:table;
				width:100%;

				li{
					float:left;
					max-width:50%;
					min-width:50%;
				}
			}
		}

		@include screen(mobile){
			ul{
				li{
					max-width:100%;
					min-width:100%;
				}
			}
		}
	}

	&.op_dot_list_gap{
		ul{
			li{
				@include margin-bottom(20px);
			}
		}
		@include screen(mobile){
			@include padding-top(10px);
			ul{
				li{

				}
			}
		}
	}
}	