//typography contents scss
@font-face {
    font-family: 'oxygenbold';
    src: url('../fonts/oxygen-bold-webfont.woff2') format('woff2'),
         url('../fonts/oxygen-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'oxygenlight';
    src: url('../fonts/oxygen-light-webfont.woff2') format('woff2'),
         url('../fonts/oxygen-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'oxygenregular';
    src: url('../fonts/oxygen-regular-webfont.woff2') format('woff2'),
         url('../fonts/oxygen-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratbold';
    src: url('../fonts/montserrat-bold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'montserratlight';
    src: url('../fonts/montserrat-light-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratmedium';
    src: url('../fonts/montserrat-medium-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratregular';
    src: url('../fonts/montserrat-regular-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratsemibold';
    src: url('../fonts/montserrat-semibold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

//keyframes
@-webkit-keyframes display {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}
@keyframes display {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-webkit-keyframes unshown {
    0% {
        opacity:1;
        z-index: 10;
    }
    100% {
        opacity:0;
        z-index:10 ;
    }
}
@keyframes unshown {
    0% {
        opacity:1;
        z-index: 10;
    }
    100% {
        opacity:0;
        z-index: 10;
    }
}
@-webkit-keyframes upfade {
    0%{
        opacity:0;
        margin-top:-35px;
    }
    100% {
        opacity: 1;
        margin-top:-12px;
    }
}

/* Standard syntax */
@keyframes upfade {
    0%{
        opacity:0;
        margin-top:-35px;
    }
    100% {
        opacity: 1;
        margin-top:-12px;
    }
}



body{
    color:$gray-600;
	@include family($oxyrg);
}
button:focus{
    outline:none;
    @include box-shadow(0,0,0,0, transparent);
}
a{
    color:$primary;
	@include transition(0.3s);
	&:hover,
	&:focus{
        color:$primary-dark;
		text-decoration:none;
		outline:none; 
	}
}
p{
    @include font(14px);
    @include line(24px);
    color:$gray-600;
    @include family($monsterrg);
}
img{
    @include maxfull;
}

//buttons
.op_btn_prime{
    @include font(16px);
    color:$white;
    height:60px;
    @include line(60px);
    @include border-none;
    @include paddingall(0, 20px, 0, 20px);
    @include family($monsterrg);
    @include display(inline-block);
    background:$primary;
    @include text-center;
    @include transition(0.3s);
    &:hover,
    &:focus{
        color:$white;
        background:$primary-dark;
    }

    &.op_sm_btn{
        height:40px;
        @include font(12px);
        @include line(40px);
    }
    &.op_btn_curve{
        @include font(14px);
        height:50px;
        @include line(50px);
        @include radius(5px);
        @include padding(0 30px);

        &.op_btn_md{
            @include font(16px);
            height:60px;
            @include line(60px);
            @include paddingall(0, 40px, 0, 40px);
        }
        &.op_btn_lg{
            @include font(18px);
            height:70px;
            @include line(70px);
            @include paddingall(0, 50px, 0, 50px);
        }
    }

    &.op_btn_md{
        @include font(18px);
        height:70px;
        @include line(70px);
        @include paddingall(0, 50px, 0, 50px);
    }
    &.op_btn_lg{
        @include font(20px);
        height:80px;
        @include line(80px);
        @include paddingall(0, 60px, 0, 60px);
    }

}
.op_btn_line{
    @include font(12px);
    color:$primary;
    height:40px;
    @include line(40px);
    @include border(1px, solid, $primary);
    @include paddingall(0, 30px, 0, 30px);
    @include family($monsterrg);
    @include display(inline-block);
    background:tranaparent;
    @include transition(0.3s);
    &.op_inverse{
        color:$white;
        @include border(1px, solid, $white);
        &:hover,
        &:focus{
            color:$primary;
            background:$white;
        }        
    }

    &:hover,
    &:focus{
        color:$white;
        background:$primary;
    }
    &.op_btn_md{
        @include font(14px);
        height:50px;
        @include line(50px);
        @include paddingall(0, 30px, 0, 30px);
    }
    &.op_btn_lg{
        @include font(16px);
        height:60px;
        @include line(60px);
        @include paddingall(0, 40px, 0, 40px);
    }
}

//title
.op_block_title{
    @include font(30px);
    color:$primary;
    @include margin(0);
    @include margin-bottom(25px);
    @include screen(mobile){
        @include font(24px);
        @include margin-bottom(10px);
    }
}

//title block
.op_heading_block{
    @include text-center;
    @include padding-bottom(40px);
    h1{
        @include font(30px);
        background: -webkit-linear-gradient(#a71252, #ca3c3c);
        background: linear-gradient(#a71252, #ca3c3c);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        @include margin(0);
        @include margin-bottom(5px);
    }   
    p{
        color:$gray-600;
        @include font(16px);
        @include family($monsterrg);
        @include margin(0);
    }
    @include screen(mobile){
        h1{
            @include font(24px);
            @include margin-bottom(10px);
        }
        p{
            @include font(14px);
        }
    }
}



.op_border_heading_block{
    @include margin-bottom(20px);
    @include position(relative);
    &:after{
        content:'';
        width:100%;
        height:2px;
        left:0;
        bottom:0;
        @include margin-bottom(12px);
        background:$bg-lightgrey;
        @include position(absolute);
        @include zindex(-1);
    }
    h3{
        @include font(24px);
        @include margin(0);
        @include padding-right(30px);
        @include display(inline-block);
        color:$primary;
        background:$white;
    }

    &.op_border_sm{
        h3{
            @include font(20px);
        }
    }
    &.op_gap_bottom{
        @include margin-bottom(50px);
    }

    @include screen(mobile){
        h3{    
            @include font(20px);
        }  
        &.op_border_sm{
        h3{
            @include font(18px);
        }
    }
    }
}




.op_little_border{
    @include text-center;
    @include padding-bottom(30px);
    h2{
        color:$primary;
        @include font(24);
        @include family($oxyrg);
        @include position(relative);
        @include margin(0);
        @include padding-bottom(8px);
        &:after{
            content:'';
            width:40px;
            height:1px;
            left:50%;
            bottom:0;
            background-color:$bg-lightgrey;
            @include margin-left(-20px);
            @include position(absolute);
        }
    }
}

//animate
.hiding{opacity:0}
.visible{opacity:1}
@include screen(mobile){
    .hiding{opacity:1}
}
@include screen(tablet){
    .hiding{opacity:1}   
}


//paddings
.op_padding40{
    padding:40px 0;
}
.op_padding-top40{
    padding-top:40px;
}
.op_padding-btm40{
    padding-bottom:40px;
}
.op_padding60{
    padding:60px 0;
}
.op_padding-top60{
    padding-top:60px;
}
.op_padding-btm60{
    padding-bottom:60px;
}
.op_padding80{
    padding:80px 0;
}
.op_padding-top80{
    padding-top:80px;
}
.op_padding-btm80{
    padding-bottom:80px;
}
@include screen(mobile){
 
    .op_padding40{
        padding:20px 0;
    }
    .op_padding-top40{
        padding-top:20px;
    }
    .op_padding-btm40{
        padding-bottom:20px;
    }
    .op_padding40{
        padding:40px 0;
    }
    .op_padding60{
        padding:40px 0;
    }
    .op_padding-top60{
        padding-top:40px;
    }
    .op_padding-btm60{
        padding-bottom:40px;
    }
    .op_padding80{
        padding:50px 0;
    }
    .op_padding-top80{
        padding-top:50px;
    }
    .op_padding-btm80{
        padding-bottom:50px;
    }   

}








