//quality promise page scss
.op_quality_sidebar{
	@include padding-left(40px);

	.op_border_heading_block {
		@include margin-bottom(30px);
	}

	@include screen(mobile){
		@include padding-left(0px);		
		@include padding-top(30px);		
		.op_border_heading_block {
			@include margin-bottom(15px);
		}
	}

	@include screen(tablet){
		@include padding-left(0px);
	}

}

.op_quality_sidebar_image{
	@include text-center;
	@include padding-bottom(50px);

	@include screen(mobile){
		@include padding-bottom(30px);
		img{
			width:150px;
		}		
	}
}

.op_quality_certificates_list{
	ul{
		@include screen(mobile){
			padding-top:15px;
		}
		@include ul-clear;
		li{
			@include display(block);
			@include margin-bottom(30px);
			a{
				color:$gray-600;
				@include font(14px);
				@include family($monsterrg);
				@include display(block);
				@include transition(0.3s);
				@include position(relative);
				@include padding-left(35px);
				&:after{
					content:'';
					width:13px;
					height:18px;
					left:0;
					top:0;
					@include position(absolute);
					background-image:url(../images/file.png);
					@include back-repeat(no-repeat);
					@include back-size(contain);
				}
				&:hover,
				&:focus{
					color:$primary;
				}
			}
		}
	}
}


//gallery
.op_galler_section{
	@include padding(30px 30px 100px);
	.op_gallery_carousel{
		.op_gallery_carousel_item {
			@include padding(0 15px);
			a{	
				//display:block;
				img{
					max-width:100%;
					
				}
			}	
		}
	}
	@include screen(mobile){
		@include padding(1px 0px 40px);
		.op_gallery_carousel{
			.op_gallery_carousel_item {
				@include padding(0 2px);
			}
		}
	}

	@include screen(tablet){
		@include padding(10px 20px 60px);
		.op_gallery_carousel{
			.op_gallery_carousel_item {
				@include padding(0 2px);
			}
		}
	}

	@include screen(tabletrotate){
		@include padding(30px 20px 80px);
		.op_gallery_carousel{
			.op_gallery_carousel_item {
				@include padding(0 8px);
			}
		}
	}
	.op_gallery_carousel_outer{
		position:relative;
	}
	.op_gal_left{
		left:0;
		margin-left:-50px;
	}
	.op_gal_right{
		right:0;
		margin-right:-50px;
	}
	.op_gal_nav{
		text-align:center;
		line-height:60px;
		font-size:45px;
		color:$primary;
		width:50px;
		height:60px;
		background:#fff;
		position:absolute;
		top:50%;
		margin-top:-30px;
		@include screen(tabletrotate){
			&.op_gal_left{
				margin-left:-20px;
			}
			&.op_gal_right{
				margin-right:-20px;
			}
		}
		@include screen(tablet){
			&.op_gal_left{
				margin-left:-20px;
			}
			&.op_gal_right{
				margin-right:-20px;
			}
		}
		@include screen(mobile){
			@include display(none);
		}
	}
}

.op_repaircenter_address_container{
	@include padding-top(10px);
	@include padding-bottom(10px);
}
.op_repaircenter_address_block{
	@include margin-bottom(30px);
	h5{
		color:$gray-600;
		@include font(14px);
		@include family($monstersbd);
		@include line(24px);
		@include margin(0);
	}	
	p{
		@include family($monsterrg);
	}
}
