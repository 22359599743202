//contact page scss
._op_map_section{
	iframe{
		width:100%;
		height:320px;
	}
}

.op_contact_content_block{}
.op_contact_form_block{
	@include padding-top(20px);
	h6{
		color:$gray-600;
		@include font(14px);
		@include family($monsterrg);
		@include margin(0);
		span{
			@include family($monsterbd);
		}
	}
	form{
		@include padding-top(40px);
		.row{
			@include margin-bottom(20px);
			@include margin-left(-10px);
			@include margin-right(-10px);
			.col-md-6,
			.col-md-12{
				@include padding-left(10px);
				@include padding-right(10px);
			}
		}
		@include screen(mobile){
			.row{
				@include margin-bottom(0px);
			}
		}
	}

	.form-control,
	.op_select_box{
		width:100%;
		height:45px;
		@include padding-left(25px);
		@include radius(0);
		@include font(14px);
		@include family($monsterrg);
		@include border(1px, solid, $bg-lightgrey);
		&:focus{
			outline:none;
			@include box-shadow(0,0,0,0, $white);
		}

		@include screen(mobile){
			@include margin-bottom(10px);
		}
	}
	textarea{
		&.form-control{
			@include padding(15px);
			height:120px;
		}
	}
	.op_select_box{
		@include padding-left(0px);
		select{
			width:100%;
			height:42px;
			@include border-none;
			@include radius(0);
			@include font(14px);
			@include padding-left(25px);
			@include family($monsterrg);
			&:focus{
				outline:none;
				@include box-shadow(0,0,0,0, $white);
			}
		}	
	}
	.op_btn_prime{
		min-width:200px;
	}

	@include screen(mobile){

	}
}




.op_contact_address_blocks{
	@include padding-left(30px);
	ul{
		@include ul-clear;
		li{
			@include display(block);
			@include padding-top(20px);
			@include padding-bottom(20px);
			@include border-bottom(1px, solid, $bg-lightgrey);
			h4{
				color:$gray-600;
				@include font(18px);
				@include family($monstersbd);
				@include margin-bottom(15px);
			}
			p{
				@include margin-bottom(0);	
				@include family($monsterrg);
			}
			&:last-child{
				@include border-none;
			}
		}
	}

	@include screen(mobile){
		@include padding-left(0px);
		@include padding-top(40px);
	}

	@include screen(tablet){
		@include padding-left(0px);
		@include padding-top(40px);
		ul{
			@include flex;
			li{
				@include border-none;
				max-width:40%;
				@include padding-left(10px);
				@include padding-right(10px);
				@include text-center;
				h4{
					@include font(16px);
					@include margin-bottom(10px);
				}
				p{
					@include font(12px);
				}
			}
		}
	}
}